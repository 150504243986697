import { fetchAllProducts } from "@app/services/services/fetchProductById";

import { PRODUCT_TYPE_SERVICE } from "@utils/constant";

const filterServices = (services = []) => {
  return services
    .filter(({ default_available }) => default_available)
    .map(service => ({
      ...service,
      isChecked: Boolean(service.default_selected),
    }));
};

export const fetchServices = async (customerId, orderType) => {
  const { data: { data: serviceData = [] } = {} } = await fetchAllProducts({
    type: PRODUCT_TYPE_SERVICE,
    customer_id: customerId,
    order_type: orderType,
  });

  return filterServices(serviceData);
};
