import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import { personInformationActions } from "@app/reducers/personInformationReducer";

import { useOrder } from "@contexts/OrderContext";
import { useStepperNavigation } from "@contexts/StepperContext";

import { useToast } from "@hooks/useToast";
import useUserPaymentInfo from "@hooks/useUserPaymentInfo";

import { fetchServices } from "./AvailableServices.utils";

const useAvailableServices = orderType => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const {
    orderUser: {
      customer: { id: customer_id },
    },
  } = useOrder();
  const personInfo = useSelector(
    state => state.personInformationReducer.personInfo
  );
  const { onNextStep } = useStepperNavigation();
  const { showErrorToast } = useToast();
  const { getPaymentMethod } = useUserPaymentInfo();

  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllServicesVisible, setIsAllServicesVisible] = useState(false);

  const visibleServices = useMemo(() => {
    if (services.length === 0) {
      return [];
    }

    if (services.length <= 6 || isAllServicesVisible) {
      return services;
    }

    const collapsedServicesList = services.slice(0, 6);

    return collapsedServicesList;
  }, [isAllServicesVisible, services]);

  const isSubmitButtonDisabled = useMemo(
    () => visibleServices.filter(({ isChecked }) => isChecked).length === 0,
    [visibleServices]
  );

  const handleToggleAllServicesVisibility = () => {
    setIsAllServicesVisible(!isAllServicesVisible);
  };

  const handleSelectService = selectedServiceId => {
    setServices(prevValue =>
      prevValue.map(service => ({
        ...service,
        isChecked:
          service.id === selectedServiceId
            ? !service.isChecked
            : service.isChecked,
      }))
    );
  };

  const handleSubmit = useCallback(() => {
    const selectedServices = services.filter(service => service.isChecked);
    const creditsServicesCount = selectedServices.reduce((acc, service) => {
      if (service.purchase_by_credits) {
        acc += 1;
      }

      return acc;
    }, 0);

    const paymentMethod = getPaymentMethod(creditsServicesCount);

    const orderDetails = [
      {
        ...personInfo,
        services: selectedServices,
        order_type: orderType,
      },
    ];

    dispatch(personInformationActions.personInfo(orderDetails));
    dispatch(personInformationActions.setPaymentMethod(paymentMethod));

    onNextStep();
  }, [dispatch, getPaymentMethod, onNextStep, orderType, personInfo, services]);

  useEffect(() => {
    const loadServices = async () => {
      try {
        setIsLoading(true);

        const servicesData = await fetchServices(customer_id, orderType);

        setServices(servicesData);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    loadServices();
  }, [
    customer_id,
    messages.exception_error_message,
    orderType,
    showErrorToast,
  ]);

  return {
    visibleServices,
    isAllServicesVisible,
    isLoading,
    isSubmitButtonDisabled,
    handleToggleAllServicesVisibility,
    handleSelectService,
    handleSubmit,
  };
};

export default useAvailableServices;
