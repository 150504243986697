import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { useUser } from "@contexts/AuthProvider";
import { useOrder } from "@contexts/OrderContext";
import { useStepperNavigation } from "@contexts/StepperContext";

import Stepper from "@pages/shared/Wizard/Stepper";

import { ROUTES } from "@utils/constant";

import { removeQueryParamValueFromURL } from "./utils";

const NOT_FOUND_INDEX = -1;

const OrderStepper = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { isLoading, setOrderUser } = useOrder();
  const { steps, onStep } = useStepperNavigation();
  const { isAdmin } = useUser();

  useEffect(() => {
    const targetUrl = `${pathname}${search}`;

    const selectedStepIndex = steps.findIndex(({ url = "" }) =>
      url.includes(removeQueryParamValueFromURL(targetUrl))
    );

    if (selectedStepIndex === NOT_FOUND_INDEX) {
      return;
    }

    onStep(selectedStepIndex);
  }, [isAdmin, pathname, search, onStep, steps]);

  const handleClose = () => {
    setOrderUser(undefined);

    if (isAdmin) {
      history.push(ROUTES.ADMIN_ORDERS_LIST.URL);
    } else {
      history.push(ROUTES.SEARCH.URL);
    }
  };

  return <Stepper isLoading={isLoading} onClose={handleClose} />;
};

OrderStepper.propTypes = {
  steps: PropTypes.array,
};

export default OrderStepper;
