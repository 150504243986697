import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { PrimaryButtonIcon } from "@components/Button";
import CardV2 from "@components/CardV2";
import Div from "@components/Div";
import { H2, H3 } from "@components/Heading";
import Icon from "@components/Icon";
import Link from "@components/Link";
import ProgressSpinner from "@components/ProgressSpinner";
import { TextMediumWeight } from "@components/Text";

import { AvailableServicesList } from "./components";

import useAvailableServices from "./AvailableServices.hooks";

const AvailableServices = ({ orderType, header }) => {
  const {
    visibleServices,
    isAllServicesVisible,
    isLoading,
    isSubmitButtonDisabled,
    handleToggleAllServicesVisibility,
    handleSelectService,
    handleSubmit,
  } = useAvailableServices(orderType);
  const { messages } = useIntl();

  return (
    <Div display="flex" flexDirection="column" gridGap={4}>
      <H2>{messages.title_available_services}</H2>

      <CardV2 header={header}>
        <Div display="flex" flexDirection="column" gridGap="30px">
          <Div
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gridGap="10px"
          >
            <H3>{`${messages.search_label_service_available}:`}</H3>

            <TextMediumWeight display={["flex", "flex", "none"]}>
              {messages.watchlist_label_price}
            </TextMediumWeight>
          </Div>

          {isLoading ? (
            <ProgressSpinner />
          ) : (
            <AvailableServicesList
              services={visibleServices}
              onSelectService={handleSelectService}
            />
          )}

          <Div>
            <Link
              width={1}
              label={
                isAllServicesVisible
                  ? messages.search_label_see_less
                  : messages.search_label_see_all
              }
              onClick={handleToggleAllServicesVisibility}
            >
              <Icon
                ml={1}
                name={isAllServicesVisible ? "up-arrow" : "download"}
              />
            </Link>
          </Div>
        </Div>
      </CardV2>

      <Div>
        <PrimaryButtonIcon
          disabled={isSubmitButtonDisabled}
          label={messages.label_next}
          onClick={handleSubmit}
          icon="icon-arrowright"
          iconPos="right"
        />
      </Div>
    </Div>
  );
};

AvailableServices.propTypes = {
  orderType: PropTypes.string,
  header: PropTypes.node,
};

export default AvailableServices;
