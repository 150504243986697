import React from "react";
import PropTypes from "prop-types";

import Div from "@components/Div";

import { AvailableServicesListItem } from "./components";

const AvailableServicesList = ({ services, onSelectService }) => {
  return (
    <Div
      display="grid"
      gridTemplateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(2, 1fr)",
      ]}
      gridColumnGap={5}
      gridRowGap="20px"
      mt={3}
    >
      {services.map(service => (
        <AvailableServicesListItem
          key={service.id}
          service={service}
          onSelect={onSelectService.bind(this, service.id)}
        />
      ))}
    </Div>
  );
};

AvailableServicesList.propTypes = {
  services: PropTypes.array,
  onSelectService: PropTypes.func,
};

export default AvailableServicesList;
