import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import Section from "@components/Section";
import Div from "@components/Div";
import { H2, H3 } from "@components/Heading";
import { default as Span } from "@components/Span";
import { LinkArrow } from "@components/Link";
import Icon from "@components/Icon";
import Container from "@components/Container";
import { HOME } from "@utils/constant";
import useHistory from "@utils/useHistory";
import breakpoints from "@utils/breakpoints";
import Config from "@src/config";

const mobileBreakPoint = Config.mobileBreakPoint;

const StyledSpan = styled(Span)`
  color: var(--blue-dark);
  font-size: var(--fs-report-not-found);
  line-height: 1;

  @media (max-width: ${breakpoints[mobileBreakPoint]}px) {
    font-size: var(--fs-report-not-found-sm);
  }
`;

export const ReportNotFound = () => {
  const { messages } = useIntl();
  const location = useHistory();

  const handleRedirect = () => {
    location.push(HOME);
  };

  return (
    <Section margin="auto" height="100%">
      <Container
        height="100%"
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Div
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <H2 mb={4}>{messages.top_title_report_not_found}</H2>
          <StyledSpan display="block" mb={4}>
            {messages.title_report_not_found}
          </StyledSpan>
          <H3 mb={4} textTransform="none" textAlign="center">
            {messages.desc_report_not_found}
          </H3>
          <Div
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <LinkArrow
              label={messages.link_label_report_not_found}
              direction="left"
              onClick={handleRedirect}
              width={1}
            >
              <Icon ml={1} name="headerarrowright" />
            </LinkArrow>
          </Div>
        </Div>
      </Container>
    </Section>
  );
};

export default ReportNotFound;
