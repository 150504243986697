import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";

export const StepperContext = createContext({
  steps: [],
  activeStepIndex: 0,
  setActiveStepIndex: () => {},
});

export const StepperProvider = ({ children, steps }) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const value = useMemo(
    () => ({
      steps,
      activeStepIndex,
      setActiveStepIndex,
    }),
    [steps, activeStepIndex, setActiveStepIndex]
  );

  return (
    <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
  );
};

StepperProvider.propTypes = {
  children: PropTypes.node,
  steps: PropTypes.array,
  numberOfSteps: PropTypes.number,
};

export const useStepper = () => {
  const context = useContext(StepperContext);
  if (!context) {
    throw new Error("useStepper must be used within a StepperProvider");
  }
  return context;
};

export const useStepperNavigation = () => {
  const { steps, activeStepIndex, setActiveStepIndex } = useStepper();

  const activeStepItem = useMemo(
    () => steps?.[activeStepIndex] ?? null,
    [activeStepIndex, steps]
  );
  const numberOfSteps = useMemo(() => steps?.length ?? 0, [steps?.length]);

  const onStep = useCallback(
    targetIndex => {
      if (targetIndex >= numberOfSteps || targetIndex < 0) {
        return;
      }

      setActiveStepIndex(targetIndex);
    },
    [numberOfSteps, setActiveStepIndex]
  );

  const onNextStep = useCallback(() => {
    const nextIndex = activeStepIndex + 1;

    onStep(nextIndex);
  }, [activeStepIndex, onStep]);

  const onPrevStep = useCallback(() => {
    const prevIndex = activeStepIndex - 1;

    onStep(prevIndex);
  }, [activeStepIndex, onStep]);

  return {
    steps,
    activeStepItem,
    activeStepIndex,
    onStep,
    onNextStep,
    onPrevStep,
  };
};
