import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import fileUploadService from "@app/services/files/fileUploadService";
import { deleteCustomerProfile } from "@app/services/users/deleteUserProfileService";
import editCustomerInfoService from "@app/services/users/editCustomerInfoService";
import userService from "@app/services/users/userService";

import Div from "@components/Div";
import { PrimaryButtonIconOutlined } from "@components/Button";
import ConfirmationPopup from "@components/ConfirmationPopup";
import EditableFieldsCard from "@components/EditableFieldsCard";
import JsonFormCard from "@components/JsonForm/JsonFormCard";
import Icon from "@components/Icon";
import ProgressSpinner from "@components/ProgressSpinner";
import { TextLargeSemiBoldWeight } from "@components/Text";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { AdminPermissions } from "@src/enum/Permissions";

import { ROUTES } from "@utils/constant";
import { addHyphenToNumber } from "@utils/utils";

import { NameSchema, ContactSchema, SettingSchema, PubSchema } from "./schema";
import { getConfig } from "./utils";
import ContentOptions from "@constants/contentOptions";

const CompanyInfo = ({ customerData = {}, id = "0", updateCustomerData }) => {
  const { hasAllPermissions } = useAuthorization();
  const history = useHistory();
  const { messages } = useIntl();
  const { showSuccessToast, showErrorToast } = useToast();

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({});
  const [user] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [setSelectedManagers] = useState(null);
  const [customerConsent] = useState(customerData.consent);

  const {
    name,
    address,
    registration_nr,
    phone,
    firstname,
    lastname,
    email,
    invoice,
    billing_email: billingEmail,
    city,
    zip_code,
    created_by,
    order_confirmation_email,
    test_account,
    pub_agreement,
    pub_date,
    integration_api_enabled,
    account_manager_id,
    is_active,
    company_type,
    document,
    delivery_description,
    delivery_place,
    delivery_time,
    interview_template,
    delivery_frequency,
    delivery_method,
    content,
    delivery_frequency_other,
    delivery_documents,
  } = customerData || {};

  // JSON VALUES FOR RENDERING ALL DROPDOWN AND MULTISELECT

  const hasWriteAccessToCustomers = hasAllPermissions([
    AdminPermissions.AdminWriteCustomers,
  ]);

  const hasDeleteAccessToCustomers = hasAllPermissions([
    AdminPermissions.AdminDeleteCustomers,
  ]);

  const formLayout = {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: ["column", "column", "column", "row"],
    gridColumnGap: 2,
  };

  const {
    company,
    contact,
    settings,
    pub,
    executionConfig,
    deliveryConfig,
    documentConfig,
  } = getConfig(
    companyDetails,
    users,
    created_by,
    customerData,
    customerConsent
  );

  const handleDeleteProfile = () => {
    setIsDeleteDialogVisible(true);
  };

  const handleCancelDeleteProfile = () => {
    setIsDeleteDialogVisible(false);
  };

  const handleDeleteSubmit = async () => {
    try {
      await deleteCustomerProfile({
        customerId: id,
      });

      showSuccessToast(messages.text_deleted_successful);

      history.push(ROUTES.ADMIN_CUSTOMERS.URL);
      return;
    } catch (e) {
      showErrorToast(messages.error);
    }
  };

  useEffect(() => {
    const formattedContent = customerData.content?.map?.(code => {
      const matchingOption = ContentOptions.find(
        option => option.code === code
      );

      return {
        name: matchingOption ? matchingOption.name : null,
        code,
      };
    });

    setCompanyDetails({
      name,
      address,
      registration_nr: addHyphenToNumber(registration_nr, "6"),
      phone: phone,
      firstname,
      lastname,
      email,
      invoice: invoice === 1,
      billing_email: billingEmail,
      city,
      zip_code,
      created_by,
      order_confirmation_email,
      test_account,
      pub_agreement,
      pub_date,
      integration_api_enabled,
      account_manager_id,
      is_active,
      company_type,
      document,
      delivery_description,
      delivery_place,
      delivery_time,
      interview_template,
      delivery_frequency,
      delivery_method,
      content: formattedContent,
      delivery_frequency_other,
      delivery_documents,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    test_account,
    pub_agreement,
    pub_date,
    name,
    registration_nr,
    phone,
    firstname,
    lastname,
    email,
    city,
    zip_code,
    order_confirmation_email,
    integration_api_enabled,
    account_manager_id,
    is_active,
    address,
    company_type,
    document,
    delivery_description,
    delivery_place,
    delivery_time,
    interview_template,
    delivery_frequency,
    delivery_method,
    content,
    delivery_frequency_other,
    delivery_documents,
  ]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);

        const response = await userService(
          "filter[type]=admin&filter[status]=active&filter[is_active]=1&per_page=100"
        );
        const { data: { data: users = [] } = {} } = response;
        const userDropDownOptions = users.map(({ name = "", id = "" }) => ({
          label: `${name}`,
          value: id,
        }));

        setUsers(userDropDownOptions);
      } catch (error) {
        showErrorToast(messages.text_no_data_found);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [id, showErrorToast, messages]);

  const handleSubmit = async values => {

    try {
      setIsLoading(true);

      const payload = {
        id,
        ...values,
        content: values.content.map(item => item.code),
      };
      if (payload.registration_nr) {
        payload.registration_nr =
          payload.registration_nr?.replace(/-/g, "") ?? "";
      }

      if (typeof payload.invoice === "boolean") {
        payload.invoice = Number(payload.invoice);
      }

      const newCompanyInfo = { ...companyDetails, ...payload };

      delete newCompanyInfo.invoice;

      if (payload.document instanceof File) {
        const formData = new FormData();

        formData.append("media", payload.document);
        formData.append("type", payload.document.type);
        formData.append("category", messages.customer_pub_document);

        const { data: fileData = {} } = await fileUploadService(formData);

        payload.pub_agreement_file_id = fileData.id || null;
        newCompanyInfo.document = fileData;
      }

      const formattedPayload = Object.keys(payload).reduce((acc, key) => {
        if (companyDetails[key] !== payload[key]) {
          acc[key] = payload[key];
        }

        return acc;
      }, {});
      delete payload.created_by;



      await editCustomerInfoService(formattedPayload);
      updateCustomerData(newCompanyInfo);

      showSuccessToast(messages.text_update_successful);
    } catch (e) {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitDeliveryDocuments = async values => {
    try {
      setIsLoading(true);

      const hasEmptyDescription = values.delivery_documents.some(
        doc => !doc.document_description?.trim()
      );

      if (hasEmptyDescription) {
        showErrorToast(messages.label_all_document_description_are_required);
        return;
      }

      const delivery_documents = await Promise.all(
        values.delivery_documents.map(({ file, file_id }) => {
          if (file_id) {
            return Promise.resolve({ data: { id: file_id } });
          }

          const formData = new FormData();

          formData.append("media", file);
          formData.append("type", file.type);
          formData.append("category", messages.customer_pub_document);

          return fileUploadService(formData);
        })
      );

      const payload = {
        id,
        delivery_documents: delivery_documents.map(({ data }, index) => ({
          file_id: data.id,
          document_description:
            values.delivery_documents[index]?.document_description,
        })),
      };

      const response = await editCustomerInfoService(payload);

      const { data: updatedCustomer } = response?.data || {};
      const newCompanyInfo = {
        ...companyDetails,
        delivery_documents: updatedCustomer.delivery_documents,
      };

      updateCustomerData(newCompanyInfo);

      showSuccessToast(messages.text_update_successful);
    } catch (e) {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Div mt={3} maxWidth="1110px !important">
      {isLoading && <ProgressSpinner />}

      <Div display="flex" flexDirection="column" width={1}>
        <TextLargeSemiBoldWeight
          fontSize={"var(--fs-text-sm) !important"}
          mb={3}
        >
          {messages.title_customer}
        </TextLargeSemiBoldWeight>
        <EditableFieldsCard
          title={messages.label_details}
          config={company}
          onSubmit={handleSubmit}
          validationSchema={NameSchema}
          isEditPermission={hasWriteAccessToCustomers}
          values={user}
          options={users}
          onChange={setSelectedManagers}
          optionFields={users}
          optionLabel={users}
          maxSelectedLabels={3}
          className="w-full md:w-20rem"
        />
        <EditableFieldsCard
          title={messages.title_contact_person}
          config={contact}
          onSubmit={handleSubmit}
          validationSchema={ContactSchema}
          isEditPermission={hasWriteAccessToCustomers}
        />
        <EditableFieldsCard
          title={messages.Settings}
          config={settings}
          onSubmit={handleSubmit}
          validationSchema={SettingSchema}
          isEditPermission={hasWriteAccessToCustomers}
        />
        <EditableFieldsCard
          title={messages.pub}
          config={pub}
          onSubmit={handleSubmit}
          validationSchema={PubSchema}
          isEditPermission={hasWriteAccessToCustomers}
        />
      </Div>

      <Div display="flex" flexDirection="column" width={1} mt={3} gridGap={3}>
        <TextLargeSemiBoldWeight fontSize={"var(--fs-text-sm) !important"}>
          {messages.admin_customer_details_delivery_information}
        </TextLargeSemiBoldWeight>

        <JsonFormCard
          title={messages.admin_customer_details_execution}
          config={executionConfig}
          onSubmit={handleSubmit}
          isEditPermission={true}
          formLayout={formLayout}
        />

        <JsonFormCard
          title={messages.admin_customer_details_delivery}
          config={deliveryConfig}
          onSubmit={handleSubmit}
          isEditPermission={true}
          formLayout={formLayout}
        />

        <JsonFormCard
          title={messages.admin_customer_document_card_documents}
          config={documentConfig}
          onSubmit={handleSubmitDeliveryDocuments}
          isEditPermission={true}
        />
      </Div>

      {hasDeleteAccessToCustomers && (
        <Div mt={2} width={1}>
          <PrimaryButtonIconOutlined
            color="red"
            width={[1, 1, "250px", "250px"]}
            margin={3}
            label={messages.label_delete_customer}
            onClick={handleDeleteProfile}
            icon={<Icon mr={2} name="rubber" />}
          />

          {isDeleteDialogVisible && (
            <ConfirmationPopup
              title={messages.label_delete_customer}
              description={messages.customer_delete_message}
              onCancelClick={handleCancelDeleteProfile}
              onAcceptClick={handleDeleteSubmit}
            />
          )}
        </Div>
      )}
    </Div>
  );
};

CompanyInfo.propTypes = {
  customerData: PropTypes.node,
  id: PropTypes.number,
  updateCustomerData: PropTypes.func,
};

export default CompanyInfo;
