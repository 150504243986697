import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { OrderProvider } from "@contexts/OrderContext";

import { useUser } from "@contexts/AuthProvider";
import { StepperProvider } from "@contexts/StepperContext";

import { ROUTES } from "@utils/constant";

import CheckoutComponent from "./Checkout";
import ChooseCustomer from "./ChooseCustomer";
import OrderConfirmationComponent from "./OrderConfirmation";
import OrderStepper from "./OrderStepper";
import SearchComponent from "./SearchResults";

const STEPS_ITEMS = [
  {
    label: <FormattedMessage id="search_text" />,
    component: <SearchComponent />,
    url: ROUTES.WIZARD_SEARCH.URL,
  },
  {
    label: <FormattedMessage id="title_select_services" />,
    component: <SearchComponent />,
    url: ROUTES.WIZARD_SELECT_SERVICES.URL,
  },
  {
    label: <FormattedMessage id="label_confirm_order" />,
    component: <CheckoutComponent />,
    url: ROUTES.WIZARD_CONFIRM_ORDER.URL,
  },
  {
    label: <FormattedMessage id="label_order_completed" />,
    component: <OrderConfirmationComponent />,
    url: ROUTES.WIZARD_ORDER_COMPLETED.URL,
  },
];

const ADMIN_STEPS_ITEMS = [
  {
    label: <FormattedMessage id="label_choose_customer" />,
    component: <ChooseCustomer />,
    url: ROUTES.ADMIN_WIZARD_SELECT_CUSTOMER.URL,
  },
];

const CreateOrderWizard = () => {
  const { isAdmin } = useUser();

  const steps = useMemo(
    () => (isAdmin ? [...ADMIN_STEPS_ITEMS, ...STEPS_ITEMS] : STEPS_ITEMS),
    [isAdmin]
  );

  return (
    <OrderProvider>
      <StepperProvider steps={steps}>
        <OrderStepper />
      </StepperProvider>
    </OrderProvider>
  );
};

export default CreateOrderWizard;
