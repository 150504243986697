import React from "react";

import AvailableServices from "@pages/shared/AvailableServices";

import { ORDER_TYPE } from "@utils/enum";

import { AvailableServicesHeader } from "./components";

const AvailableServicesNoSSN = () => {
  return (
    <AvailableServices
      orderType={ORDER_TYPE.INDIVIDUAL_WITHOUT_SSN}
      header={<AvailableServicesHeader />}
    />
  );
};

export default AvailableServicesNoSSN;
