import { useDispatch } from "react-redux";

import { personInformationActions } from "@app/reducers/personInformationReducer";

import { useStepperNavigation } from "@contexts/StepperContext";

export const useCompanyDetails = () => {
  const dispatch = useDispatch();
  const { onNextStep } = useStepperNavigation();

  const handleSubmit = data => {
    dispatch(personInformationActions.personInfo(data));

    onNextStep();
  };

  return {
    handleSubmit,
  };
};
