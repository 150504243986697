import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import patchAccountStatus from "@app/services/billingAccounts/patchAccountStatus";
import editCustomerInfoService from "@app/services/users/editCustomerInfoService";

import Container from "@components/Container";
import DataTable from "@components/DataTableV2/DataTable";
import Div from "@components/Div";
import { SmallEllipseIcon } from "@components/Icon";
import Switch from "@components/Switches";
import { TextMediumWeight } from "@components/Text";
import Tooltip from "@components/ToolTip";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { AdminPermissions } from "@src/enum/Permissions";

import { INPUT } from "@utils/constant";

import BillingAccountDialog from "./BillingAccountDialog";

const { INVOICE, INVOICE_ATTACHMENT } = INPUT.NAME;

const BillingAccountsTab = ({
  customer_id,
  isInvoiceEnabled,
  isInvoiceAttachmentsEnabled,
  updateCustomerData,
  hasActiveSubscription,
}) => {
  const { hasAllPermissions } = useAuthorization();
  const history = useHistory();
  const { messages } = useIntl();
  const { showErrorToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [showAddBillingAccountDialog, setShowAddBillingAccountDialog] =
    useState(false);

  const isDataTableVisible = hasActiveSubscription || isInvoiceEnabled;

  const dataTableRef = useRef(null);

  const hasWriteAccessToBillings = hasAllPermissions([
    AdminPermissions.AdminWriteBilling,
  ]);

  const reload = () => {
    if (!dataTableRef.current) {
      return;
    }

    dataTableRef.current.reload();
  };

  const handleAddNewBillingAccount = () => {
    setShowAddBillingAccountDialog(!showAddBillingAccountDialog);
    reload();
  };

  const checkHasNoWriteAccessToBilling = () => {
    return !hasWriteAccessToBillings;
  };

  const updateCustomerInfo = async payload => {
    try {
      setLoading(true);

      await editCustomerInfoService({ ...payload, id: customer_id });

      updateCustomerData(payload);
    } catch (error) {
      showErrorToast(messages.exception_error_message);
    } finally {
      setLoading(false);
    }
  };

  const handleOnInvoicePaymentTypeChange = async event => {
    const { target: { value } = {} } = event;

    const invoiceValue = Number(value);
    const payload = {
      invoice: invoiceValue,
    };

    await updateCustomerInfo(payload);
  };

  const handleOnInvoiceAttachmentsChange = async event => {
    const { target: { value } = {} } = event;

    const invoiceAttachmentsValue = Number(value);
    const payload = {
      invoice_attachments: invoiceAttachmentsValue,
    };

    await updateCustomerInfo(payload);
  };

  const handleRedirectToUpdateBilling = values => {
    const { id } = values;

    history.push(`/admin/kunder/${customer_id}/faktura/${id}/detaljer`);
  };

  const handleDeactivateAccount = async values => {
    const { id } = values;

    try {
      setLoading(true);

      await patchAccountStatus({
        billing_account_id: id,
        active: 0,
      });
    } catch (error) {
      showErrorToast(messages.exception_error_message);
    } finally {
      setLoading(false);
    }
  };

  const searchableColumns = [
    "name",
    "email_invoice",
    "your_reference",
    "invoice_remark",
  ];

  const config = {
    header: {
      title: "header_billing_accounts",
      actions: [
        {
          type: "button",
          label: messages.button_add_new,
          icon: "plus",
          id: "create-billing",
          onClick: handleAddNewBillingAccount,
          isHidden: !hasWriteAccessToBillings,
        },
      ],
    },
    row_selection_checkbox: false,
    enable_filter: true,
    backend_querying: true,
    no_records_message: "billing_no_records_found",
    row_selection_actions: [],
    enable_search: true,
    enable_query_builder: true,
    enable_csv_download: true,
    csv_filename: `billing-accounts.csv`,
    api: {
      resource: `/billing-accounts`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        ["filter[customer_id]", customer_id],
        ["filter[active]", 1],
        [
          "fields",
          [
            "id",
            "fortnox_customer_id",
            "name",
            "email_invoice",
            "your_reference",
            "invoice_remark",
            "invoice_type",
            "active",
            "address_1",
            "address_2",
            "zip_code",
            "city",
            "country_code",
          ],
        ],
      ],
    },
    columns: [
      {
        title: "label_billing_account_name",
        db_field: "name",
        type: "text",
        sortable: true,
      },
      {
        title: "invoice_email_label",
        db_field: "email_invoice",
        type: "text",
        sortable: true,
      },
      {
        title: "placeholder_your_reference_person",
        db_field: "your_reference",
        type: "text",
        sortable: true,
      },
      {
        title: "heading_invoice_reference",
        db_field: "invoice_remark",
        type: "text",
      },
      {
        title: "label_fortnox_id",
        db_field: "fortnox_customer_id",
        type: "text",
        isHidden: true,
        visibleInCSV: true,
      },
      {
        title: "label_invoice_type",
        db_field: "invoice_type",
        type: "text",
        isHidden: true,
        visibleInCSV: true,
      },
      {
        title: "active",
        db_field: "active",
        type: "text",
        isHidden: true,
        visibleInCSV: true,
      },
      {
        title: "label_invoice_address_line1",
        db_field: "address_1",
        type: "text",
        isHidden: true,
        visibleInCSV: true,
      },
      {
        title: "label_invoice_address_line2",
        db_field: "address_2",
        type: "text",
        isHidden: true,
        visibleInCSV: true,
      },
      {
        title: "zip_code",
        db_field: "zip_code",
        type: "text",
        isHidden: true,
        visibleInCSV: true,
      },
      {
        title: "label_city",
        db_field: "city",
        type: "text",
        isHidden: true,
        visibleInCSV: true,
      },
      {
        title: "label_country",
        db_field: "country_code",
        type: "text",
        isHidden: true,
        visibleInCSV: true,
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            label: messages.view_details,
            icon: "icon-showeye",
            onClick: handleRedirectToUpdateBilling,
          },
          {
            label: messages.deactivate_acc,
            icon: "icon-rubber",
            shouldHideAction: checkHasNoWriteAccessToBilling,
            onClick: handleDeactivateAccount,
          },
        ],
      },
    ],
  };

  return (
    <Container m="0px !important">
      <Div pb={3} display="flex" alignItems="center" gridGap={4}>
        <Div display="flex" alignItems="center">
          <Switch
            name={INVOICE}
            checked={isInvoiceEnabled}
            onChange={handleOnInvoicePaymentTypeChange}
            mr={3}
          />
          <TextMediumWeight>
            {messages.label_invoiced_in_arrears}
          </TextMediumWeight>
          <SmallEllipseIcon ml={2} className="invoice-info" name="info">
            <Tooltip
              content={messages.pay_as_u_go_info}
              target="invoice-info"
            />
          </SmallEllipseIcon>
        </Div>

        <Div display="flex" alignItems="center">
          <Switch
            name={INVOICE_ATTACHMENT}
            checked={isInvoiceAttachmentsEnabled}
            onChange={handleOnInvoiceAttachmentsChange}
            mr={3}
          />
          <TextMediumWeight>
            {messages.label_invoice_attachment}
          </TextMediumWeight>
        </Div>
      </Div>

      {isDataTableVisible && <DataTable config={config} resetData={!loading} />}

      {showAddBillingAccountDialog && (
        <BillingAccountDialog handleClose={handleAddNewBillingAccount} />
      )}
    </Container>
  );
};
BillingAccountsTab.propTypes = {
  customer_id: PropTypes.string,
  isInvoiceEnabled: PropTypes.bool,
  isInvoiceAttachmentsEnabled: PropTypes.bool,
  hasActiveSubscription: PropTypes.bool,
  updateCustomerData: PropTypes.func,
};
export default BillingAccountsTab;
