import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { authActions } from "@app/reducers/authReducer";
import { klarnaActions } from "@app/reducers/klarnaReducer";
import { personInformationActions } from "@app/reducers/personInformationReducer";

import personInformationSearchService from "@app/services/search/personInformationSearchService";
import { fetchAllProducts } from "@app/services/services/fetchProductById";

import Container from "@components/Container";
import { default as Div } from "@components/Div";
import ErrorDialog from "@components/ErrorDialog";
import Section from "@components/Section";

import { useOrder } from "@contexts/OrderContext";
import { useStepperNavigation } from "@contexts/StepperContext";
import { useUser } from "@contexts/AuthProvider";

import useUserPaymentInfo from "@hooks/useUserPaymentInfo";

import { trackEvent, trackPageView } from "@src/analyticsFunctions";

import {
  SSNNUMBER,
  SINGLE,
  MULTIPLE,
  PROTECTED,
  CONSENT_OPTIONAL,
  PRODUCT_TYPE_SERVICE,
  ROUTES,
  SELECTED_PRODUCTS,
  CONSENT_MANDATORY,
} from "@utils/constant";
import { ORDER_TYPE } from "@utils/enum";
import { getPinWithFullYear, splitString } from "@utils/utils";

import AvailableServicesForCandidates from "./AvailableServicesForCandidates";
import { SinglePINLoader, MultiPINLoader } from "./Loader";
import SSNSearch from "./SSNSearch";
import INITIAL_VALUES from "./types";

const fetchServices = async customerId => {
  const { data: { data: serviceData = [] } = {} } = await fetchAllProducts({
    type: PRODUCT_TYPE_SERVICE,
    customer_id: customerId,
  });

  return serviceData;
};

const SearchResults = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { messages } = useIntl();
  const location = useLocation();
  const { orderUser } = useOrder();
  const { onStep, onNextStep } = useStepperNavigation();
  const { isAdmin } = useUser();
  const { getPaymentMethod } = useUserPaymentInfo();

  const userConsent = orderUser?.customer?.consent || CONSENT_OPTIONAL;

  const [servicesData, setServicesData] = useState([]);
  const [data, setData] = useState(() => ({
    ...INITIAL_VALUES,
    consentCheck: userConsent === CONSENT_OPTIONAL,
  }));
  const [isFetching, setIsFetching] = useState(false);
  const [ssnNumber, setSSNNumber] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isServiceNotAvailable, setIsServiceNotAvailable] = useState(false);

  useEffect(() => {
    if (!orderUser) {
      if (isAdmin) {
        history.push(ROUTES.ADMIN_WIZARD_SELECT_CUSTOMER.URL);
      } else {
        history.push(ROUTES.WIZARD_SEARCH.URL);
      }
    }
  }, [isAdmin, orderUser, history]);

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const numberSsn = useMemo(
    () =>
      splitString(
        query.get(SSNNUMBER) ? query.get(SSNNUMBER).replaceAll(",", "\n") : ""
      ),
    [query]
  );
  const selectedProducts = useMemo(
    () => query.get(SELECTED_PRODUCTS)?.split(","),
    [query]
  );

  const _ssnNumber = useMemo(
    () =>
      numberSsn.map(value => {
        const pin = value.replaceAll("-", "");
        if (pin.length === 10) {
          const appendedValue = getPinWithFullYear(pin);
          return appendedValue;
        }
        return pin;
      }),
    [numberSsn]
  );

  const resetStateValues = () => {
    setData(prevValue => ({ ...prevValue, candidates: [] }));
    setSSNNumber([]);
  };

  const sanitizeData = useCallback(
    (servicesData, responseData, _ssnNumber, selectedProducts) => {
      const isCases = servicesData.some(({ is_case }) => is_case);
      const defaultServiceData = servicesData
        .filter(({ is_case }) => (isCases ? is_case : true))
        .map((service = {}) => {
          const serviceId = String(service?.id);
          const defaultSelected = selectedProducts
            ? selectedProducts.includes(serviceId)
            : service.default_selected > 0;

          return {
            ...service,
            isChecked: defaultSelected,
          };
        });

      _ssnNumber.forEach(ssn => {
        const pin = ssn && ssn.replaceAll("-", "");
        if (
          !responseData.some(resData => {
            return resData.ssn === pin && resData.ssn_status !== PROTECTED;
          })
        ) {
          return responseData.push({
            ssn: ssn,
            error: true,
          });
        }
      });

      return {
        candidates: responseData
          .filter(i => i.ssn_status !== PROTECTED)
          .map(ssnDetails => ({
            ...ssnDetails,
            email: "",
            phone: "",
            services: defaultServiceData,
            showAllServices: false,
          })),
      };
    },
    []
  );

  const getPersonInformation = useCallback(
    async (_ssnNumber = []) => {
      try {
        resetStateValues();
        setIsError(false);
        setIsFetching(true);

        const [serviceData = [], { data: responseData = [] }] =
          await Promise.all([
            fetchServices(orderUser?.customer?.id),
            personInformationSearchService(_ssnNumber),
          ]);
        const availableServices = serviceData.filter(
          ({ default_available }) => default_available === 1
        );

        setServicesData(availableServices);

        if (responseData.length === 0) {
          const errorResponse = {
            candidates: _ssnNumber?.map(singleSsn => ({
              ssn: singleSsn,
              error: true,
              isChecked: false,
            })),
          };

          setData(prevValue => ({ ...prevValue, ...errorResponse }));
          setIsError(true);
        } else {
          setData(prevValue => ({
            ...prevValue,
            ...sanitizeData(
              availableServices,
              responseData,
              _ssnNumber,
              selectedProducts
            ),
          }));
        }
      } catch (e) {
        const errorResponse = {
          candidates: [
            {
              ssn: _ssnNumber,
              error: true,
              isChecked: false,
            },
          ],
        };

        setData(prevValue => ({ ...prevValue, ...errorResponse }));
        setIsError(true);
      } finally {
        setSSNNumber(_ssnNumber);
        setIsFetching(false);
      }
    },
    [orderUser?.customer?.id, sanitizeData, selectedProducts]
  );

  useEffect(() => {
    trackPageView();

    if (!_ssnNumber.length) {
      return;
    }

    getPersonInformation(_ssnNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.candidates?.length) {
      onStep(isAdmin ? 2 : 1);
    }
  }, [data?.candidates?.length, isAdmin, onStep]);

  const onSearchSSN = (pin = []) => {
    if (!pin || pin.length === 0) {
      return;
    }

    const param = pin.join(",");

    history.replace({
      pathname: location.pathname,
      search: `?ssnnumber=${param}`,
    });

    resetStateValues();

    return getPersonInformation(pin);
  };

  const handleCheckoutRedirection = useCallback(
    async values => {
      if (!orderUser?.customer) {
        return;
      }

      const { consentCheck, candidates } = values;

      setIsFetching(true);
      trackEvent();

      const selectedServicesCount = data.candidates.filter(
        val => val.isChecked !== false
      ).length;

      const paymentMethod = getPaymentMethod(selectedServicesCount);

      dispatch(klarnaActions.allSSN(data.candidates.map(val => val.ssn)));
      dispatch(
        authActions.isConsent(consentCheck || userConsent === CONSENT_MANDATORY)
      );

      const oderDetails = candidates
        .filter(o => o.services.filter(x => x.isChecked).length > 0)
        .map(y => ({
          ...y,
          consent: consentCheck || userConsent === CONSENT_MANDATORY ? 1 : 0,
          services: y.services.filter(o => o.isChecked),
          order_type: ORDER_TYPE.INDIVIDUAL_SSN,
        }));

      dispatch(personInformationActions.personInfo(oderDetails));
      dispatch(personInformationActions.setPaymentMethod(paymentMethod));

      setIsFetching(false);
      onNextStep();
    },
    [
      orderUser?.customer,
      data.candidates,
      getPaymentMethod,
      dispatch,
      userConsent,
      onNextStep,
    ]
  );

  useEffect(() => {
    if (!selectedProducts?.length || !data?.candidates?.length) {
      return;
    }

    const redirectToCheckout = async () => {
      const allServices = await fetchServices(orderUser?.customer?.id);

      const formattedServices = allServices
        ?.filter(({ id }) => selectedProducts.includes(String(id)))
        .map(service => ({ ...service, isChecked: true }));

      if (formattedServices.length === 0) {
        setIsServiceNotAvailable(true);

        return;
      }

      const formattedCandidates = data.candidates.map(candidate => ({
        ...candidate,
        services: formattedServices,
      }));

      const payload = {
        ...data,
        candidates: formattedCandidates,
      };

      handleCheckoutRedirection(payload);
    };

    redirectToCheckout();
  }, [
    data,
    handleCheckoutRedirection,
    orderUser?.customer?.id,
    selectedProducts,
  ]);

  const getLoader = () => (ssnNumber.length > 1 ? MULTIPLE : SINGLE);

  const layoutType = getLoader();

  const renderLoader = () =>
    layoutType === SINGLE ? (
      <SinglePINLoader count={[ssnNumber]} />
    ) : (
      <MultiPINLoader count={ssnNumber} />
    );

  const hideServiceNotAvailableDialog = () => {
    setIsServiceNotAvailable(false);

    window.close();
  };

  const renderForm = () => (
    <>
      {isServiceNotAvailable && (
        <ErrorDialog
          errorMessage={messages.error_product_not_available_for_account}
          onHide={hideServiceNotAvailableDialog}
          onConfirm={hideServiceNotAvailableDialog}
        />
      )}

      <SSNSearch
        ssnNumbers={ssnNumber}
        customerId={orderUser?.customer?.id}
        onSearchSSN={onSearchSSN}
        onChange={setSSNNumber}
      />

      {!!data.candidates.length && (
        <AvailableServicesForCandidates
          data={data}
          setData={setData}
          ssnNumber={ssnNumber}
          isError={isError}
          setIsFetching={setIsFetching}
          servicesData={servicesData}
          setSSNNumber={setSSNNumber}
          onSubmit={handleCheckoutRedirection}
        />
      )}
    </>
  );

  return (
    <Section px={0} py={0}>
      <Container>
        <Div width={1}>{isFetching ? renderLoader() : renderForm()}</Div>
      </Container>
    </Section>
  );
};

export default SearchResults;
