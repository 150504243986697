import React, { useState } from "react";
import Proptypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { JsonView, darkStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import Container from "@components/Container";
import Div from "@components/Div";
import Dialog from "@components/Dialog";
import Breadcrumb from "@components/Breadcrumb";
import { H1, H2, H4 } from "@components/Heading";
import styled from "styled-components";
import {
  Text,
  TextLargeSemiBoldWeight,
  ColouredSemiBoldText,
} from "@components/Text";
import Span from "@components/Span";
import {
  ROUTES,
  STATUS_EXPIRED,
  STATUS_PAID,
  STATUS_CANCELLED,
  STATUS_NOT_PAID,
  STATUS_ERROR,
  STATUS_COMPLETED,
  ADMIN_CUSTOMER_DETAILS,
  COMPANY,
  ROLE_ANALYSIS,
  PENDING_CONSENT_TO_SHARE,
  ACTAPUBLICA_ANALYSIS,
  // GENERAL_ANALYSIS
} from "@utils/constant";
import useAnalysisReport from "@utils/useAnalysisReport";
import { LinkArrow } from "@components/Link";
import Icon from "@components/Icon";
import ReportList from "./reportlist";
import AnalysisParts from "./AnalysisParts";
import SaveAndSendDialog from "./SaveAndSendDialog";
import updateGrade from "@app/services/reports/updateGrade";
import DoneModal from "@components/Report/LegacyReport/DoneModal";
import ErrorModal from "@components/Report/LegacyReport/ErrorModal";
import Timelog from "../../shared/orderDetails/Timelog";
import ResultsCard from "../../shared/orderDetails/ResultsCard";
import Card from "@components/Card";
import { addHyphenToNumber } from "@utils/utils";
const AnalysDetails = ({
  analysData = {},
  cardData,
  isAdmin,
  redirectTo = ROUTES.ANALYS_LIST.URL,
  fetchReportDetails = () => {},
}) => {
  const history = useHistory();
  const { messages } = useIntl();
  const [showSavePopup, setShowSavePopup] = useState();
  const [grade, setGrade] = useState("");
  const [showDonePopup, setShowDonePopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const {
    order_id: orderId = "",
    report_status: reportStatus = "",
    customer = {},
    verifiera_response: veriferaResponse = null,
    analysis_type: analysisType = ROLE_ANALYSIS,
    id: analysisId = "",
    order_item: orderItem = [],
    product: product = {},
    grades,
    personal_number = "",
    name: person_name = "",
    raw_data,
  } = analysData || {};

  const StyledContainer = styled(Container)`
    position: relative;
    background: var(--blue-lightest);
    box-shadow: none;
  `;

  const rawData = JSON.stringify(raw_data) || null;
  const { status = "", watchList = {} } = cardData;
  const isRoleAnalysis = analysisType === ROLE_ANALYSIS;
  const isActaPublica = analysisType === ACTAPUBLICA_ANALYSIS;

  const timeLogSpacing = status === STATUS_COMPLETED ? 5 : 3;
  const {
    name: company = "",
    type = "",
    customer_name: customerName = "",
    email: customerEmail = "",
  } = customer || {};

  const companyName = type === COMPANY ? company : customerName;

  const breadCrumbItems = [
    {
      label: messages.analysis_text,
      url: redirectTo,
    },
    { label: `#${orderId}` },
  ];

  const Deviations = useAnalysisReport({ type: analysisType });

  const handleRedirectToOrderDetails = () => {
    const { order_id: orderId = "" } = analysData;
    history.push(`${ROUTES.ADMIN_ORDER_DETAILS.URL}?id=${orderId}`);
  };

  const redirectToCustomerDetails = () => {
    const { id = "", status: customerStatus = "" } = customer;
    history.push(
      `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_CUSTOMER_DETAILS}`,
      { id: id, status: customerStatus }
    );
  };

  const handleStatus = () => {
    switch (reportStatus) {
      case STATUS_PAID:
        return (
          <ColouredSemiBoldText color="var(--blue-dark)">
            {messages.paid}
          </ColouredSemiBoldText>
        );
      case STATUS_NOT_PAID:
        return (
          <ColouredSemiBoldText color="var(--blue-dark)">
            {messages.not_paid}
          </ColouredSemiBoldText>
        );
      case STATUS_CANCELLED:
        return (
          <ColouredSemiBoldText color="var(--red)">
            {messages.cancelled}
          </ColouredSemiBoldText>
        );
      case STATUS_COMPLETED:
        return (
          <ColouredSemiBoldText>{messages.completed}</ColouredSemiBoldText>
        );
      case STATUS_EXPIRED:
        return <ColouredSemiBoldText>{messages.expired}</ColouredSemiBoldText>;
      case STATUS_ERROR:
        return (
          <ColouredSemiBoldText color="var(--red)">
            {messages.waiting}
          </ColouredSemiBoldText>
        );
      case PENDING_CONSENT_TO_SHARE:
        return (
          <TextLargeSemiBoldWeight>
            {messages.consent_label_pending_consent_share}
          </TextLargeSemiBoldWeight>
        );
      default:
        return (
          <TextLargeSemiBoldWeight>
            {messages.consent_title_Pending}
          </TextLargeSemiBoldWeight>
        );
    }
  };

  const handleSaveAndSendPopup = () => {
    setShowSavePopup(!showSavePopup);
  };

  const handleClosePopup = () => {
    setShowDonePopup(false);
    setShowErrorPopup(false);
    fetchReportDetails();
  };

  const handleSaveOption = async () => {
    try {
      const response = await updateGrade(
        analysisId,
        grade === 0 ? null : grade
      );
      if (response) {
        setShowSavePopup(false);
        setShowDonePopup(true);
      }
    } catch (e) {
      // ignore error
      setShowErrorPopup(true);
    }
  };

  return (
    <Div bg="var(--white)" minHeight="100vh" padding="16px">
      {showSavePopup && (
        <SaveAndSendDialog
          handleCloseDialog={handleSaveAndSendPopup}
          handleSave={handleSaveOption}
        />
      )}
      {showDonePopup && (
        <Dialog
          visible="displayBasic"
          draggable={false}
          onHide={handleClosePopup}
          width={["90%", "90%", "90%", 445]}
        >
          <DoneModal
            handleConfirmPopup={handleClosePopup}
            content={messages.result_email_sent}
          />
        </Dialog>
      )}
      {showErrorPopup && (
        <Dialog
          visible="displayBasic"
          draggable={false}
          onHide={handleClosePopup}
          width={["90%", "90%", "90%", 445]}
        >
          <ErrorModal
            handleConfirmPopup={handleClosePopup}
            content={messages.error_grade_change}
          />
        </Dialog>
      )}
      <Div mb={40}>
        <Container m={"0 !important"} pl={3}>
          <Div>
            <Breadcrumb pl={0} pt={0} items={breadCrumbItems} />
          </Div>
          <Div pb={2}>
            <Div
              display={["block", "block", "flex", "flex"]}
              alignItems="center"
              justifyContent="space-between"
            >
              <H1>{`${messages.title_detail_report} #${orderId}`}</H1>
              {handleStatus()}
            </Div>
            <Text mt={3} pl={1} display="block">
              <LinkArrow
                label={messages.label_order_page}
                direction="right"
                variant="secondary"
                mr={3}
                handleClick={handleRedirectToOrderDetails}
              >
                <Span px={1}>
                  <Icon name="headerarrowright" />
                </Span>
              </LinkArrow>
            </Text>
          </Div>
          {
            <Div>
              <H2 mb={2}>{messages.title_customer}</H2>
              <Div
                display="flex"
                flexDirection={["column", "column", "column", "row"]}
                width={[1, 1, 3 / 4]}
              >
                <Div pt={2} pb={[0, 4]}>
                  <ul>
                    <li>
                      <TextLargeSemiBoldWeight
                        display="inline-block"
                        my={2}
                        pr={1}
                      >
                        {`${messages.label_company_name}:`}
                      </TextLargeSemiBoldWeight>
                      <Text>{companyName}</Text>
                    </li>
                    <li>
                      <TextLargeSemiBoldWeight
                        display="inline-block"
                        my={2}
                        pr={1}
                      >
                        {`${messages.label_email}:`}
                      </TextLargeSemiBoldWeight>
                      <Text>{customerEmail}</Text>
                    </li>
                  </ul>
                </Div>
                <Div ml={[0, 6]} pl={[0, 3]} pt={[0, 2]} pb={4}>
                  <ul>
                    <li>
                      <TextLargeSemiBoldWeight
                        display="inline-block"
                        my={2}
                        pr={1}
                      >
                        {`${messages.user}:`}
                      </TextLargeSemiBoldWeight>
                      <Text>{customerName}</Text>
                    </li>
                  </ul>
                  <LinkArrow
                    label={messages.customer_details}
                    direction="right"
                    variant="secondary"
                    mt={2}
                    onClick={redirectToCustomerDetails}
                  >
                    <Span px={1}>
                      <Icon name="headerarrowright" />
                    </Span>
                  </LinkArrow>
                </Div>
              </Div>
            </Div>
          }
          <>
            {isRoleAnalysis && (
              <>
                <AnalysisParts
                  analysData={analysData}
                  setGrade={setGrade}
                  grade={grade}
                  handleSaveAndSendPopup={handleSaveAndSendPopup}
                  status={status}
                />
                <ReportList analysData={analysData} />
                {veriferaResponse && (
                  <Div>
                    <H2 my={24}>{messages.title_raw_data}</H2>
                    <JsonView
                      data={JSON.parse(veriferaResponse)}
                      style={darkStyles}
                      shouldInitiallyExpand={() => true}
                    />
                  </Div>
                )}
              </>
            )}
            <>
              {!isRoleAnalysis && (
                <>
                  <Card mb={5} p={0} maxWidth="1110px" borderRadius={10}>
                    <StyledContainer
                      color=" var(--blue-lightest)"
                      width={1}
                      py={4}
                      px={[3, 4]}
                      maxWidth="1110px"
                    >
                      <Div
                        display="flex"
                        alignItems={["left", "center"]}
                        justifyContent="space-between"
                        flexDirection={["column", "row"]}
                      >
                        <H2>{person_name}</H2>
                        <Div
                          pt={[1, 0]}
                          mt={[3, 0]}
                          display="flex"
                          flexDirection={["row", "column"]}
                          justifyContent="space-between"
                          alignItems={"flex-end"}
                        >
                          <Text>{messages.label_personal_number}</Text>
                          <H4 fontWeight={"600 !important"}>
                            {addHyphenToNumber(personal_number.toString(), 8)}
                          </H4>
                        </Div>
                      </Div>
                    </StyledContainer>
                    <Container
                      width={1}
                      py={[2, 3]}
                      pl={[3, 4]}
                      color={"var(--grey-dark)"}
                    >
                      <ResultsCard
                        product={product}
                        grades={grades}
                        orderItem={orderItem}
                        status={status}
                        isDetails={true}
                        isReport={true}
                        isAdmin={isAdmin}
                        isWatchList={watchList && watchList !== null}
                        report={analysData || {}}
                        showAnalysLink={false}
                        fetchReportDetails={fetchReportDetails}
                      />
                    </Container>
                  </Card>
                  <ReportList
                    analysData={analysData}
                    isRoleAnalysis={isRoleAnalysis}
                    isActaPublica={isActaPublica}
                  />
                  <Deviations report={analysData} isAnalysisDetailPage />
                  {isActaPublica && rawData && (
                    <Div>
                      <H2 my={24}>{messages.title_raw_data}</H2>
                      <JsonView
                        data={JSON.parse(rawData)}
                        style={darkStyles}
                        shouldInitiallyExpand={() => true}
                      />
                    </Div>
                  )}
                </>
              )}
            </>
            <Div mb={timeLogSpacing}>
              <Timelog logs={analysData.logs} ssn={personal_number} />
            </Div>
          </>
        </Container>
      </Div>
    </Div>
  );
};

AnalysDetails.propTypes = {
  analysData: Proptypes.array,
  cardData: Proptypes.array,
  isAdmin: Proptypes.bool,
  redirectTo: Proptypes.string,
  fetchReportDetails: Proptypes.func,
};

export default AnalysDetails;
