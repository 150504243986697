import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import createCustomerService from "@app/services/auth/createCustomerService";
import fileUploadService from "@app/services/files/fileUploadService";

import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import ProgressSpinner from "@components/ProgressSpinner";
import SurveyForm from "@components/Survey/SurveyForm";

import { useToast } from "@hooks/useToast";
import { base64ToBlob } from "@utils/utils";

import CreateCustomerConfig from "./CreateCustomerConfig.json";

const CustomerAccountDialog = ({ handleClose = () => {} }) => {
  const { messages } = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const { showSuccessToast, showErrorToast } = useToast();
  const surveyRef = useRef(null);

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};

    handleFormSubmit(data);
  };

  const initialValues = {
    name: "",
    registration_nr: "",
    address: "",
    zip_code: "",
    city: "",
    account_manager_id: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    consent: "",
    is_active: "active",
    order_confirmation_email: "",
    integration_api_enabled: "",
    test_account: "",
    pub_agreement: "",
    pub_date: "",
    billing_email: "",
    company_type: "",
    pub_agreement_file_id: "",
    document: "",
    delivery_description: "",
    delivery_place: "",
    delivery_time: "",
    interview_template: "",
    delivery_frequency: "",
    delivery_method: "",
    content: [],
    delivery_frequency_other: "",
    delivery_documents: [],
  };

  const handleFormSubmit = async values => {
    // eslint-disable-next-line no-console
    console.log(values);
    setIsLoading(true);
    try {
      const COMPANY = "company";
      const type = COMPANY;

      let formData = new FormData();
      if (values.file_name?.length > 0) {
        const fileObject = values.file_name[0];
        if (fileObject?.content?.startsWith("data:")) {
          const blob = base64ToBlob(fileObject.content, fileObject.type);
          const file = new File([blob], fileObject.name, {
            type: fileObject.type,
          });

          formData.append("media", file);
          formData.append("type", fileObject.type);
          formData.append("category", messages.customer_pub_document);
        }
      }

      let fileUploadResponse = {};
      if (values.file_name) {
        fileUploadResponse = await fileUploadService(formData);
      }

      const { data: uploadedFileData = {} } = fileUploadResponse;

      const {
        name,
        registration_nr,
        address,
        zip_code,
        city,
        account_manager_id,
        firstname,
        lastname,
        email,
        phone,
        consent,
        is_active,
        order_confirmation_email,
        integration_api_enabled,
        test_account,
        pub_agreement,
        pub_date,
        billing_email,
        company_type,
        delivery_description,
        delivery_place,
        delivery_time,
        interview_template,
        delivery_frequency,
        delivery_method,
        content,
        delivery_frequency_other,
        delivery_documents,
      } = values;
      // Prepare payload for customer creation
      let payload = {
        type: type,
        name,
        registration_nr,
        address,
        zip_code,
        city,
        account_manager_id,
        firstname,
        lastname,
        email,
        phone,
        consent,
        is_active,
        order_confirmation_email,
        integration_api_enabled,
        test_account, // Ensure boolean value
        pub_agreement,
        pub_date,
        billing_email,
        company_type,
        pub_agreement_file_id: uploadedFileData.id || null,
        delivery_description,
        delivery_place,
        delivery_time,
        interview_template,
        delivery_frequency,
        delivery_method,
        content: content || [],
        delivery_frequency_other,
        delivery_documents,
      };

      if (values?.delivery_documents?.length > 0) {
        const deliveryDocumentsResponses = await Promise.all(
          values.delivery_documents.map(async ({ file, file_id }) => {
            if (file_id) {
              return { data: { id: file_id } };
            }
            if (!file?.[0]?.content?.startsWith("data:")) {
              return { data: { id: null } };
            }
            const blob = base64ToBlob(file[0].content, file[0].type);
            const formattedFile = new File([blob], file[0].name, {
              type: file[0].type,
            });

            const deliveryFormData = new FormData();
            deliveryFormData.append("media", formattedFile);
            deliveryFormData.append("type", file[0].type);
            deliveryFormData.append("category", messages.customer_pub_document);

            return await fileUploadService(deliveryFormData);
          })
        );

        payload.delivery_documents = deliveryDocumentsResponses.map(
          ({ data }, index) => ({
            file_id: data.id || null,
            document_description:
              values.delivery_documents[index]?.document_description || "",
          })
        );
      }

      await createCustomerService(payload);

      showSuccessToast(messages.label_customer_added);
      handleClose();
    } catch (e) {
      const errors = e.response?.data?.errors;

      if (errors) {
        const errorField = Object.keys(errors)[0];
        const errorMessage = errors[errorField]?.[0];
        const translatedMessage = messages[`${errorMessage}`];
        showErrorToast(translatedMessage || e.response.data.message);
      } else {
        showErrorToast("An unexpected error occurred.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Div>
      {isLoading && <ProgressSpinner />}

      <FullSizeDialog
        title={messages.title_create_customer}
        onClose={handleClose}
        className="create-customer"
      >
        <Div className="create-customer">
          <SurveyForm
            data={initialValues}
            formConfig={CreateCustomerConfig}
            onRefLoaded={handleLoadSurveyRef}
            onComplete={handleSave}
            showCompleteButton={true}
            onClose={handleClose}
          />
        </Div>
      </FullSizeDialog>
    </Div>
  );
};

CustomerAccountDialog.propTypes = {
  handleClose: PropTypes.func,
};

export default CustomerAccountDialog;
