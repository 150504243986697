import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import styled from "styled-components";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import ProgressSpinner from "@components/ProgressSpinner";
import { useToast } from "@hooks/useToast";
import { SurveyForm } from "@components/Survey";
import { useIntl } from "react-intl";
import createEmailTemplateService from "@app/services/emailTemplate/createEmailTemplateService";
import emailTemplateConfig from "./emailTemplateConfig.json";
const StyledCreateBilling = styled.div`
  width: 1110px;
`;
const CreateEmailTemplateDialog = ({ handleClose = () => {} }) => {
  const { messages } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const surveyRef = useRef(null);
  const { showErrorToast, showSuccessToast } = useToast();
  const handleUpdateCase = useCallback(
    async (data) => {
      try {
        setIsLoading(true);
        await createEmailTemplateService(data);
        showSuccessToast(messages.label_email_template_created);
        handleClose();
      } catch (error) {
        showErrorToast(messages.label_back);
      } finally {
        setIsLoading(false);
      }
    },
    [messages, showErrorToast, handleClose, showSuccessToast]
  );
  const handleCompleteSurvey = useCallback(
    (survey) => {
      handleUpdateCase(survey);
    },
    [handleUpdateCase]
  );
  const handleLoadSurveyRef = (ref) => {
    surveyRef.current = ref;
  };
  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};
    handleUpdateCase(data);
  };
  return (
    <Div>
      {isLoading && <ProgressSpinner />}
      <FullSizeDialog title={messages.label_add_email_template} onClose={handleClose}>
        <StyledCreateBilling>
          <Div bgColor="var(--white)">
            <SurveyForm
              formConfig={emailTemplateConfig}
              onComplete={handleCompleteSurvey}
              onRefLoaded={handleLoadSurveyRef}
            />
          </Div>
          {!isLoading && (
            <Div
              display="flex"
              flexDirection={["column", "column", "row", "row"]}
              gridGap={3}
              ml={4}
            >
              <PrimaryButton
              minWidth="150px"
                label={messages.label_save}
                onClick={handleSave}
              />
              <PrimaryButtonOutlined
                backgroundColor="transparent !important"
                minWidth="150px"
                label={messages.label_back}
                onClick={handleClose}
              />
            </Div>
          )}
        </StyledCreateBilling>
      </FullSizeDialog>
    </Div>
  );
};
CreateEmailTemplateDialog.propTypes = {
  handleClose: PropTypes.func,
};
export default CreateEmailTemplateDialog;