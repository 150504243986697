import React from "react";
import { FormattedMessage } from "react-intl";

import { OrderProvider } from "@contexts/OrderContext";
import { StepperProvider } from "@contexts/StepperContext";

import { ROUTES } from "@utils/constant";

import OrderForCompanyStepper from "./OrderForCompanyStepper";
import { AvailableServicesCompany, CompanyDetails } from "./components";

import CheckoutComponent from "../CreateOrderWizard/Checkout";
import OrderConfirmationComponent from "../CreateOrderWizard/OrderConfirmation";

const STEPS = [
  {
    label: <FormattedMessage id="label_start" />,
    component: <CompanyDetails />,
    url: ROUTES.ORDER_COMPANY_DETAILS.URL,
  },
  {
    label: <FormattedMessage id="title_select_services" />,
    component: <AvailableServicesCompany />,
    url: ROUTES.ORDER_COMPANY_SELECT_SERVICES.URL,
  },
  {
    label: <FormattedMessage id="title_checkout" />,
    component: <CheckoutComponent />,
    url: ROUTES.ORDER_COMPANY_CHECKOUT.URL,
  },
  {
    label: <FormattedMessage id="label_confirm_order" />,
    component: <OrderConfirmationComponent />,
    url: ROUTES.ORDER_COMPANY_ORDER_CONFIRMATION.URL,
  },
];

const OrderForCompany = () => {
  return (
    <OrderProvider>
      <StepperProvider steps={STEPS}>
        <OrderForCompanyStepper />
      </StepperProvider>
    </OrderProvider>
  );
};

export default OrderForCompany;
