import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Div from "@components/Div";
import { H4 } from "@components/Heading";
import { Text } from "@components/Text";

const AvailableServicesHeader = () => {
  const { messages } = useIntl();
  const { person_first_name, person_last_name, date_of_birth } = useSelector(
    state => state.personInformationReducer.personInfo
  );

  return (
    <Div
      display="flex"
      flexDirection={["column", "column", "row"]}
      gridGap={["20px", "20px", "60px"]}
    >
      <Div
        display="flex"
        flexDirection={["row", "row", "column"]}
        alignItems={["center", "center", "flex-start"]}
        justifyContent="space-between"
        gridGap="10px"
      >
        <Text>{messages.label_firstname}</Text>

        <H4>{person_first_name}</H4>
      </Div>

      <Div
        display="flex"
        flexDirection={["column", "column", "row"]}
        gridGap={["20px", "20px", "54px"]}
      >
        <Div
          display="flex"
          flexDirection={["row", "row", "column"]}
          alignItems={["center", "center", "flex-start"]}
          justifyContent="space-between"
          gridGap="10px"
        >
          <Text>{messages.label_last_name}</Text>

          <H4>{person_last_name}</H4>
        </Div>

        <Div
          display="flex"
          flexDirection={["row", "row", "column"]}
          alignItems={["center", "center", "flex-start"]}
          justifyContent="space-between"
          gridGap="10px"
        >
          <Text>{messages.label_date_of_birth}</Text>

          <H4>{date_of_birth}</H4>
        </Div>
      </Div>
    </Div>
  );
};

AvailableServicesHeader.propTypes = {
  candidate: PropTypes.object,
};

export default AvailableServicesHeader;
