import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Div from "@components/Div";
import { H4 } from "@components/Heading";
import { Text } from "@components/Text";

const AvailableServicesCompanyHeader = () => {
  const { messages } = useIntl();
  const { company_name, organisation_number } = useSelector(
    state => state.personInformationReducer.personInfo
  );

  return (
    <Div
      display="flex"
      flexDirection={["column", "column", "row"]}
      gridGap={["20px", "20px", "60px"]}
      justifyContent={["center", "center", "space-between"]}
    >
      <Div
        display="flex"
        flexDirection={["row", "row", "column"]}
        alignItems={["center", "center", "flex-start"]}
        justifyContent="space-between"
        gridGap="10px"
      >
        <Text>{messages.label_company_name}</Text>

        <H4>{company_name}</H4>
      </Div>

      <Div
        display="flex"
        flexDirection={["row", "row", "column"]}
        alignItems={["center", "center", "flex-start"]}
        justifyContent="space-between"
        gridGap="10px"
      >
        <Text>{messages.label_organisation_number}</Text>

        <H4>{organisation_number}</H4>
      </Div>
    </Div>
  );
};

export default AvailableServicesCompanyHeader;
