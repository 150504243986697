import React from "react";
import { useHistory } from "react-router-dom";

import { useUser } from "@contexts/AuthProvider";
import { useOrder } from "@contexts/OrderContext";

import Stepper from "@pages/shared/Wizard/Stepper";

import { ROUTES } from "@utils/constant";

const OrderForCompanyStepper = () => {
  const history = useHistory();
  const { isLoading, setOrderUser } = useOrder();
  const { isAdmin } = useUser();

  const handleClose = () => {
    setOrderUser(undefined);

    if (isAdmin) {
      history.push(ROUTES.ADMIN_ORDERS_LIST.URL);
    } else {
      history.push(ROUTES.SEARCH.URL);
    }
  };

  return (
    <Stepper isLoading={isLoading} onClose={handleClose} />
  );
};

export default OrderForCompanyStepper;