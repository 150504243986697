import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import PrivacyPolicyFooter from "@components/PrivacyPolicyFooter";

import { useStepperNavigation } from "@contexts/StepperContext";
import StepperHeader from "./StepperHeader";

const Stepper = ({ isLoading, onClose }) => {
  const { activeStepItem } = useStepperNavigation();

  useEffect(() => {
    if (!activeStepItem?.url) {
      return;
    }

    window.history.pushState(null, "", activeStepItem?.url);
  }, [activeStepItem]);

  return (
    <FullSizeDialog header={<StepperHeader onClose={onClose} />}>
      <Div
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        {!isLoading && (
          <>
            <Div pb={4}>{activeStepItem?.component}</Div>

            <PrivacyPolicyFooter />
          </>
        )}
      </Div>
    </FullSizeDialog>
  );
};

Stepper.propTypes = {
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Stepper;
