const host = "https://api.test-t6dnbai-tbtwobhlrthdi.eu-5.platformsh.site/";
const wpHost = "https://stage.bakgrundsanalys.se";

export default {
  domain: host,
  BASE_URL: `${host}api/v1/`,
  baseName: "/",
  language: "sv",
  mobileBreakPoint: "desktopSmall",
  WP_URL: wpHost,
  markerDestinationId: "6516b46f0ed8ef89c0dce485",
  APP_URL: "https://test-t6dnbai-tbtwobhlrthdi.eu-5.platformsh.site",
  SURVEY_JS_API_KEY:
    "MGNkOTA4MzItMWFmZi00OWFiLWE2MmYtNjBjNGI1MjllYzMyOzE9MjAyNS0wMy0xNA==",
  CREDIT_REPORT_PRODUCT_ID: 109,
  AXFOOD_CUSTOMER_ID: 251,
};
