import React from "react";

import AvailableServices from "@pages/shared/AvailableServices";

import { ORDER_TYPE } from "@utils/enum";

import { AvailableServicesCompanyHeader } from "./components";

const AvailableServicesCompany = () => {
  return (
    <AvailableServices
      orderType={ORDER_TYPE.COMPANY}
      header={<AvailableServicesCompanyHeader />}
    />
  );
};

export default AvailableServicesCompany;
