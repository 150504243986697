import React from "react";
import { useIntl } from "react-intl";

import CompanyCheck from "@assets/company_check.jpg";

import Card from "@components/Card";
import Div from "@components/Div";
import { H1 } from "@components/Heading";
import Logo from "@components/Logo";
import { TextMediumWeight } from "@components/Text";

import CompanyDetailsForm from "./components/CompanyDetailsForm";
import { useCompanyDetails } from "./CompanyDetails.hooks";

const CompanyDetails = () => {
  const { handleSubmit } = useCompanyDetails();
  const { messages } = useIntl();

  return (
    <Div display="flex" flexDirection="column" w={1} gridGap={4}>
      <Div
        display="flex"
        flexDirection={["column-reverse", "column-reverse", "row"]}
        gridGap={3}
      >
        <Div width={1}>
          <Card p={3} borderRadius="10px">
            <Div display="flex" flexDirection="column" gridGap="1.5rem">
              <H1>{messages.title_company_check}</H1>

              <TextMediumWeight color="var(--grey) !important">
                {messages.details_company_check}
              </TextMediumWeight>
            </Div>
          </Card>
        </Div>

        <Logo
          logo={CompanyCheck}
          borderRadius="10px"
          width={[1, 1, "50%"]}
          maxWidth={["100%", "100%", "448px"]}
          height="133px"
        />
      </Div>

      <CompanyDetailsForm onSubmit={handleSubmit} />
    </Div>
  );
};

export default CompanyDetails;
