import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as Yup from "yup";

import { authActions } from "@app/reducers/authReducer";
import editCustomerInfoService from "@app/services/users/editCustomerInfoService";

import Breadcrumb from "@components/Breadcrumb";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import EditableFieldsCard from "@components/EditableFieldsCard";
import { default as Tabs } from "@components/Tabs";

import UserContainer from "@layout/UserContainer";

import { loadActiveSubscriptionData } from "@utils/common";
import {
  ROUTES,
  // NO_CONSENT,
  // CONSENT_OPTIONAL,
  // CONSENT_MANDATORY,
} from "@utils/constant";
import { FORM_INPUT_TYPE } from "@utils/enum";
import { addHyphenToNumber, ValidateOrganisationNumber } from "@utils/utils";

import { EditCompanyForm } from "./EditCompanyForm";
import { ModalHeader } from "./ModalHeader";
import IntegrationsAPIList from "./tabs/IntegrationsAPIList";

import BillingAccounts from "../billingAccounts/BillingAccounts";
import ProgressSpinner from "@components/ProgressSpinner";
import { useToast } from "@hooks/useToast";
import useAuthorization from "@hooks/useAuthorization";
import { CustomerPermissions } from "@src/enum/Permissions";

export const CompanyInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { messages } = useIntl();
  const userInfo = useSelector(state => state.authReducer.userInfo);
  const { hasAllPermissions } = useAuthorization();
  const hasEditAccessToOrg = hasAllPermissions([
    CustomerPermissions.CustomerWriteCompanyInformation,
  ]);
  const [openDialog, setOpenDialog] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSubscription, setActiveSubscription] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { showSuccessToast, showErrorToast } = useToast();

  const { customer = {} } = userInfo;
  const {
    id: companyId,
    invoice = false,
    integration_api_enabled = false,
  } = customer;

  const NameSchema = Yup.object().shape({
    name: Yup.string()
      .typeError(<FormattedMessage id="validation_empty_company_name" />)
      .required(<FormattedMessage id="validation_empty_company_name" />),
    address: Yup.string().required(
      <FormattedMessage id="validation_empty_company_address" />
    ),
    registration_nr: Yup.string()
      .required(<FormattedMessage id="validation_empty_company_name" />)
      .test({
        name: "valid-company-number",
        message: <FormattedMessage id="validation_valid_company_number" />,
        test: ValidateOrganisationNumber,
      }),
    city: Yup.string().required(
      <FormattedMessage id="validation_enter_city" />
    ),
    zip_code: Yup.string().required(
      <FormattedMessage id="validation_enter_zip_code" />
    ),
  });
  const ContactSchema = Yup.object().shape({
    firstname: Yup.string().required(
      <FormattedMessage id="validation_empty_name" />
    ),
    lastname: Yup.string().required(
      <FormattedMessage id="validation_empty_last_name" />
    ),
    email: Yup.string().required(
      <FormattedMessage id="validation_valid_email_input" />
    ),
    phone: Yup.string()
      .typeError(messages.validation_valid_phone_number)
      .required(<FormattedMessage id="validation_empty_phone_number" />),
  });

  const handleModal = useCallback(() => {
    setOpenDialog(!openDialog);
  }, [openDialog]);

  const [companyDetails, setCompanyDetails] = useState({});

  const company = [
    {
      key: "name",
      translationKey: "label_company_name",
      value: userInfo.customer?.name,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "registration_nr",
      translationKey: "label_organisation_number",
      value: userInfo.customer?.registration_nr,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
      maxLength: 11,
    },
    {
      key: "address",
      translationKey: "label_company_address",
      value: userInfo.customer?.address,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "zip_code",
      translationKey: "label_postal_code",
      value: userInfo.customer?.zip_code,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "city",
      translationKey: "label_city",
      value: userInfo.customer?.city,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
  ];
  const contact = [
    {
      key: "firstname",
      translationKey: "label_firstname",
      value: userInfo.customer?.firstname,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "lastname",
      translationKey: "label_last_name",
      value: userInfo.customer?.lastname,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "email",
      translationKey: "label_email",
      value: userInfo.customer?.email,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "phone",
      translationKey: "label_phone",
      value: userInfo.customer?.phone,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
  ];
  // const consentDropDownOptions = [
  //   {
  //     label: messages.consent_mandatory,
  //     value: CONSENT_MANDATORY,
  //     message: messages.consent_mandatory_message,
  //   },
  //   {
  //     label: messages.consent_optional,
  //     value: CONSENT_OPTIONAL,
  //     message: messages.consent_optional_message,
  //   },
  //   {
  //     label: messages.consent_not_needed,
  //     value: NO_CONSENT,
  //     message: messages.consent_not_needed_message,
  //   },
  // ];
  // const settings = [
  //   {
  //     key: "consent",
  //     translationKey: "consent_title",
  //     value: userInfo.customer?.consent,
  //     type: FORM_INPUT_TYPE.DROPDOWN,
  //     mt: 3,
  //     name: "consentValue",
  //     options: consentDropDownOptions,
  //     width: [1, 1, 450, 450],
  //     required: true,
  //   },
  // ];

  useEffect(() => {
    setCompanyDetails({
      name: userInfo.customer?.name,
      company_address: userInfo.customer?.address,
      registration_nr: addHyphenToNumber(
        userInfo.customer?.registration_nr,
        "6"
      ),
      phone: userInfo.customer?.phone,
      firstname: userInfo.customer?.firstname,
      lastname: userInfo.customer?.lastname,
      email: userInfo.customer?.email,
      invoice: invoice === 1,
      billing_email: userInfo.customer?.billingEmail,
      id: companyId,
    });
  }, [userInfo.customer, companyId, invoice]);

  const handleSubmit = async values => {
    setIsLoading(true);
    const newCompanyInfo = { ...companyDetails, ...values };
    delete newCompanyInfo.invoice;
    try {
      await editCustomerInfoService({
        id: companyId,
        ...newCompanyInfo,
      });

      dispatch(
        authActions.storeUserInfo({
          ...userInfo,
          customer: {
            ...userInfo.customer,
            ...newCompanyInfo,
          },
        })
      );

      showSuccessToast(messages.text_update_successful);
    } catch (_) {
      showErrorToast(messages.exception_error_message);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    const getSubscriptionStatus = async () => {
      const response = await loadActiveSubscriptionData({ customer: customer });
      setActiveSubscription(response);
    };
    if (companyId) {
      getSubscriptionStatus();
    }
  }, [companyId, customer]);

  const tabview = () => {
    return (
      <Div display="flex" flexDirection="column">
        <EditableFieldsCard
          title={messages.label_organization_info}
          config={company}
          isEditPermission={hasEditAccessToOrg}
          onSubmit={handleSubmit}
          validationSchema={NameSchema}
        />
        <EditableFieldsCard
          title={messages.title_contact_person}
          config={contact}
          isEditPermission={hasEditAccessToOrg}
          onSubmit={handleSubmit}
          validationSchema={ContactSchema}
        />
        {/* <EditableFieldsCard
          title={messages.consent_title}
          config={settings}
          onSubmit={handleSubmit}
          isEditPermission={hasEditAccessToOrg}
        /> */}
      </Div>
    );
  };

  const viewtab = tabview();
  const tabViewContent = useMemo(
    () => [
      {
        title:
          messages.organisation.charAt(0).toUpperCase() +
          messages.organisation.slice(1),
        content: viewtab,
        url: ROUTES.COMPANY_INFO.URL,
      },
      {
        title: messages.tab_billing,
        content: <BillingAccounts customer_id={companyId} />,
        url: ROUTES.BILLING_ACCOUNTS_LIST.URL,
      },
      {
        title: messages.header_api,
        content: <IntegrationsAPIList />,
        url: ROUTES.INTEGRATIONS_API_LIST.URL,
        isHidden: !integration_api_enabled,
      },
    ],
    [
      companyId,
      integration_api_enabled,
      messages.organisation,
      messages.tab_billing,
      messages.header_api,
      viewtab,
    ]
  );

  useEffect(() => {
    const path = history.location.pathname;
    const selectedTabIndex = Math.max(
      tabViewContent.findIndex(item => item.url === path),
      0
    );

    setActiveIndex(selectedTabIndex);
  }, [history.location.pathname, tabViewContent]);

  const handleTabChange = e => {
    const targetUrl = tabViewContent[e.index].url;

    history.push(targetUrl);
  };

  let headerConfig = {
    title:
      messages.organisation.charAt(0).toUpperCase() +
      messages.organisation.slice(1),
  };

  const breadCrumbItems = [
    {
      label: messages.my_profile,
    },
    { label: messages.title_organisation },
  ];

  return (
    <UserContainer config={headerConfig}>
      <Breadcrumb p={0} items={breadCrumbItems} />
      {isLoading && <ProgressSpinner />}
      {openDialog && (
        <Dialog
          header={<ModalHeader />}
          visible="displayBasic"
          draggable={false}
          onHide={handleModal}
          width={[1, 1, 1, "auto"]}
          m={[3, 3, 3, "auto"]}
        >
          <EditCompanyForm
            id={companyId}
            userInfo={userInfo}
            onHideModal={handleModal}
          />
        </Dialog>
      )}

      {invoice || activeSubscription ? (
        <Tabs
          content={tabViewContent}
          onTabChange={handleTabChange}
          activeIndex={activeIndex}
        />
      ) : (
        tabview()
      )}
    </UserContainer>
  );
};

export default CompanyInfo;
