import React from "react";
import { FormattedMessage } from "react-intl";

import { OrderProvider } from "@contexts/OrderContext";
import { StepperProvider } from "@contexts/StepperContext";

import { ROUTES } from "@utils/constant";

import { CandidateInfo, AvailableServicesNoSSN } from "./components";
import OrderWithNoSSNStepper from "./OrderWithNoSSNStepper";
import CheckoutComponent from "../CreateOrderWizard/Checkout";
import OrderConfirmationComponent from "../CreateOrderWizard/OrderConfirmation";

const STEPS = [
  {
    label: <FormattedMessage id="label_start" />,
    component: <CandidateInfo />,
    url: ROUTES.ORDER_WITH_NO_SSN_DETAILS.URL,
  },
  {
    label: <FormattedMessage id="title_select_services" />,
    component: <AvailableServicesNoSSN />,
    url: ROUTES.ORDER_WITH_NO_SSN_SELECT_SERVICES.URL,
  },
  {
    label: <FormattedMessage id="title_checkout" />,
    component: <CheckoutComponent />,
    url: ROUTES.ORDER_WITH_NO_SSN_CHECKOUT.URL,
  },
  {
    label: <FormattedMessage id="label_confirm_order" />,
    component: <OrderConfirmationComponent />,
    url: ROUTES.ORDER_WITH_NO_SSN_ORDER_CONFIRMATION.URL,
  },
];

const OrderWithNoSSNWrapper = () => {
  return (
    <OrderProvider>
      <StepperProvider steps={STEPS}>
        <OrderWithNoSSNStepper />
      </StepperProvider>
    </OrderProvider>
  );
};

export default OrderWithNoSSNWrapper;
