const Routes = {
  HOME: { URL: "/" },
  LOGIN: { URL: "/inlogg" },
  FORGOT_PASSWORD: { URL: "/glomt-losenord" },
  RESET_PASSWORD: { URL: "/aterstall-losenord" },
  SET_PASSWORD: { URL: "/välj-lösenord" },
  REGISTER_ACCOUNT: { URL: "/registrera" },
  SEARCH: { URL: "/sok" },
  PAGE_NOT_FOUND: { URL: "/Sidan-kan-inte-hittas" },
  REPORT_NOT_FOUND: { URL: "/hittade-inte" },
  INTERVJU: { URL: "/intervju" },
  BESTALL: { URL: "/bestall" },
  INTERNATIONELLBAKGRUNDSANALYS: { URL: "/internationell-bakgrundsanalys" },
  COUNSELLING: { URL: "/fordjupning" },
  CANDIDATE: { URL: "/candidate/:consent_id" },
  CANDIDATE_LOGIN: {
    URL: "/kp/:candidate_id/inlogg",
  },

  CHECKOUT: { URL: "/checkout" },
  ACCESSDENIED: { URL: "/access-denied" },
  ORDER_CONFIRMATION: { URL: "/bestallningsbekraftelse" },
  SUBSCRIPTION_ORDER_CONFIRMATION: { URL: "/subscription-order-confirmation" },
  ORDER_RESULTS: { URL: "/rapport" },
  REPORT_PDF: { URL: "/rapport-pdf" },
  SUBSCRIPTION_CHECKOUT: { URL: "/checkout/package" },
  UPGRADE_SUBSCRIPTION: { URL: "/upgrade-subscription" },

  ADMIN_FORGOT_PASSWORD: { URL: "/admin/glomt-losenord", IS_ADMIN: true },
  ADMIN_RESET_PASSWORD: { URL: "/admin/aterstall-losenord", IS_ADMIN: true },
  ADMIN_LOGIN: { URL: "/admin/inlogg", IS_ADMIN: true },
  ADMIN_ORDER_DETAILS: { URL: "/admin/bestallningar/detaljer", IS_ADMIN: true },
  ADMIN_USERS_LIST: { URL: "/admin/anvandare", IS_ADMIN: true },
  ADMIN_ADMINS_LIST: {
    URL: "/admin/anvandare/administratorer",
    IS_ADMIN: true,
  },
  ADMIN_CUSTOMERS_LIST: { URL: "/admin/anvandare/kunder", IS_ADMIN: true },
  ADMIN_ORDERS_LIST: { URL: "/admin/bestallningar", IS_ADMIN: true },
  ADMIN_ANALYSIS_LIST: { URL: "/admin/rapporter", IS_ADMIN: true },
  ADMIN_ASSIGNMENTS_LIST: { URL: "/admin/uppdrag", IS_ADMIN: true },
  ADMIN_CUSTOMERS: { URL: "/admin/kunder", IS_ADMIN: true },
  ADMIN_CREATE_CUSTOMER: { URL: "/admin/kunder/skapa-kund", IS_ADMIN: true },
  ASSIGNMENT_DETAILS: { URL: "/admin/uppdrag/detaljer", IS_ADMIN: true },
  CUSTOMER_DETAILS: { URL: "/admin/kunder/:id/detaljer", IS_ADMIN: true },
  CUSTOMER_USERS: { URL: "/admin/kunder/:id/anvandare", IS_ADMIN: true },
  CUSTOMER_ORDERS: { URL: "/admin/kunder/:id/bestallningar", IS_ADMIN: true },
  CUSTOMER_CREDITS: {
    URL: "/admin/kunder/:id/abonnemang/:subscription_id",
    IS_ADMIN: true,
  },
  CUSTOMER_ANALYSIS: { URL: "/admin/kunder/:id/rapporter", IS_ADMIN: true },
  CUSTOMER_PRODUCTS: { URL: "/admin/kunder/:id/tjanster", IS_ADMIN: true },
  ADMIN_CUSTOMER_COMMENTS: {
    URL: "/admin/kunder/:id/kommentarer",
    IS_ADMIN: true,
  },
  ADMIN_WATCHLIST: { URL: "/admin/kunder/:id/bevakningslista", IS_ADMIN: true },
  ADMIN_REGISTRATION_COMPLETED: {
    URL: "/admin/registrering-avslutad",
    IS_ADMIN: true,
  },
  ADMIN_FORGOT_PASSWORD_SUCCESSFUL: {
    URL: "/admin/losenordsaterstallning-lyckades",
    IS_ADMIN: true,
  },
  ADMIN_COMPLETE_REGISTRATION: {
    URL: "/admin/avsluta-registrering",
    IS_ADMIN: true,
  },
  REGISTRATION_SUCCESSFUL: { URL: "/registrering-lyckades" },
  REGISTER_WITH_BANKID: { URL: "/registrera-med-bankid" },
  USER_COMPLETE_REGISTRATION: { URL: "/avsluta-registrering" },
  USER_REGISTRATION_COMPLETED: { URL: "/registrering-avslutad" },
  USER_FORGOT_PASSWORD_SUCCESSFUL: { URL: "/losenordsaterstallning-lyckades" },
  USER_PROFILE: { URL: "/mittkonto/anvandarprofil" },
  ORDERS_LIST: { URL: "/mittkonto/bestallningar" },
  ALLANALYSIS_LIST: { URL: "/mittkonto/rapporter" },
  SUB_USERS_LIST: { URL: "/mittkonto/anvandare" },
  ORDERS_DETAILS: { URL: "/mittkonto/bestallningar/detaljer" },
  COMPANY_INFO: { URL: "/mittkonto/foretagsinformation" },
  CREATE_BILLING_ACCOUNT: {
    URL: "/mittkonto/foretagsinformation/faktura/skapa-fakturakonto",
  },
  BILLING_ACCOUNT_INFO: {
    URL: "/mittkonto/foretagsinformation/faktura/:billing_account_id",
  },
  ADMIN_MY_PROFILE: { URL: "/admin/anvandarprofil", IS_ADMIN: true },
  USER_CREDITS: { URL: "/mittkonto/credits" },
  NEW_WATCH_LIST: { URL: "/mittkonto/ny-bevakningslista" },
  WATCH_LIST_DETAILS: { URL: "/mittkonto/bevakningslista/:id" },
  SUBSCRIPTION_DETAILS: {
    URL: "/admin/subscription/:subscription_id",
  },
  SUBSCRIPTION_ORDERS: {
    URL: "/admin/subscription/:subscription_id/bestallningar",
  },
  ANALYS_DETAILS: {
    URL: "/admin/rapporter/:id",
  },
  ANALYS_LIST: { URL: "/admin/rapporter" },
  SUBSCRIPTION: { URL: "/mittkonto/abonnemang" },
  SUBSCRIPTION_ORDERS_MY_ACCOUNTS: { URL: "/mittkonto/abonnemang/orders" },
  SUBSCRIPTION_LIST: { URL: "/admin/subscription-list" },
  CONTACT_US_ID: { URL: "/#h-kontakta-oss" },
  WP_SUBSCRIPTION: { URL: "/abonnemang" },
  WP_ABOUT_US: { URL: "/om-oss" },
  WP_SERVICES: { URL: "/tjanster" },
  WP_BLOG: { URL: "/blogg" },
  USER_WATCH_LIST: { URL: "/mittkonto/bevakningslista" },
  WATCH_LIST_OBJECT_DETAIL: {
    URL: "/mittkonto/bestallningar/object/detaljer",
  },
  ADMIN_CREATE_WATCH_LIST: {
    URL: "/admin/kunder/:id/skapa-bevakningslista",
    IS_ADMIN: true,
  },
  ADMIN_WATCH_LIST_DETAIL: {
    URL: "/admin/kunder/:customer_id/bevakningslista/:id/detaljer",
    IS_ADMIN: true,
  },
  ADMIN_CUSTOMER_ACTIVITY_LOG: {
    URL: "/admin/kunder/:id/aktivitetslogg",
    IS_ADMIN: true,
  },
  ADMIN_ACTIVITY_LOG_DETAILS: {
    URL: "/admin/aktivitetslogg/:id/detaljer",
    IS_ADMIN: true,
  },
  ADMIN_WATCH_LIST_OBJECT_DETAIL: {
    URL: "/admin/bestallningar/detaljer/object",
    IS_ADMIN: true,
  },
  ADMIN_CREATE_SUBSCRIPTION: {
    URL: "/admin/produkter/abonnemang/skapa-abonnemang",
    IS_ADMIN: true,
  },
  ADMIN_CREATE_SERVICE: {
    URL: "/admin/produkter/tjanster/skapa-tjanst",
    IS_ADMIN: true,
  },
  ADMIN_PRODUCTS_LIST: { URL: "/admin/produkter", IS_ADMIN: true },
  ADMIN_PRODUCTS_SUBSCRIPTION_LIST: {
    URL: "/admin/produkter/abonnemang",
    IS_ADMIN: true,
  },
  ADMIN_UPDATE_SUBSCRIPTION: {
    URL: "/admin/produkter/abonnemang/:id",
    IS_ADMIN: true,
  },
  ADMIN_UPDATE_SERVICE_PRODUCT: {
    URL: "/admin/produkter/tjanster/:id/detaljer",
    IS_ADMIN: true,
  },
  ADMIN_UPDATE_SERVICE_PRODUCT_FORM_CONFIGURATION: {
    URL: "/admin/produkter/tjanster/:id/formularkonfiguration",
    IS_ADMIN: true,
  },
  ADMIN_UPDATE_SERVICE_PRODUCT_RULES: {
    URL: "/admin/produkter/tjanster/:id/regler",
    IS_ADMIN: true,
  },
  BILLING_ACCOUNTS_LIST: { URL: "/mittkonto/foretagsinformation/faktura" },
  ADMIN_BILLING_ACCOUNTS_LIST: {
    URL: "/admin/kunder/:id/faktura",
    IS_ADMIN: true,
  },
  ADMIN_CREATE_BILLING_ACCOUNT: {
    URL: "/admin/kunder/:id/faktura/skapa-fakturakonto",
    IS_ADMIN: true,
  },
  ADMIN_BILLING_ACCOUNT_INFO: {
    URL: "/admin/kunder/:customer_id/faktura/:billing_account_id/detaljer",
    IS_ADMIN: true,
  },
  ADMIN_BILLING_ACCOUNT_ORDERS: {
    URL: "/admin/kunder/:customer_id/faktura/:billing_account_id/bestallningar",
    IS_ADMIN: true,
  },
  INTEGRATIONS_API_LIST: {
    URL: "/mittkonto/foretagsinformation/integrationer",
  },
  ADMIN_INTEGRATIONS_API_LIST: {
    URL: "/admin/kunder/:id/integrationer",
    IS_ADMIN: true,
  },
  ADMIN_UN_BILLED_SERVICES: {
    URL: "/admin/faktura/ofakturerat/tjanster",
    IS_ADMIN: true,
  },
  ADMIN_UN_BILLED_SUBSCRIPTIONS: {
    URL: "/admin/faktura/ofakturerat/abonnemang",
    IS_ADMIN: true,
  },
  ADMIN_INVOICE_ACCOUNTS: {
    URL: "/admin/faktura/fakturor",
    IS_ADMIN: true,
  },
  ADMIN_BILLING_ACCOUNTS: {
    URL: "/admin/faktura/faktureringskonton",
    IS_ADMIN: true,
  },
  ADMIN_COUPONS_LIST: {
    URL: "/admin/produkter/kuponger",
    IS_ADMIN: true,
  },
  ADMIN_CREATE_COUPON: {
    URL: "/admin/produkter/kuponger/skapa-ny-kupong",
    IS_ADMIN: true,
  },
  ADMIN_EDIT_COUPON: {
    URL: "/admin/produkter/kuponger/:coupon_id",
    IS_ADMIN: true,
  },
  ERROR_404: {
    URL: "/error404",
  },
  ADMIN_CASES_LIST: {
    URL: "/admin/arenden",
    IS_ADMIN: true,
  },
  ADMIN_EMAIL_LOG: {
    URL: "/admin/arenden/epost-logg",
    IS_ADMIN: true,
  },
  ADMIN_EMAIL_LOG_DETAILS: {
    URL: "/admin/arenden/epost-logg/:email_id",
    IS_ADMIN: true,
  },
  ADMIN_CASE_DETAILS: {
    URL: "/admin/arenden/detaljer/:case_id",
    IS_ADMIN: true,
  },
  ADMIN_CASE_SOURCE_DATA: {
    URL: "/admin/arenden/:case_id/kalldata",
    IS_ADMIN: true,
  },
  ADMIN_CASE_REPORT: {
    URL: "/admin/arenden/:case_id/rapport",
    IS_ADMIN: true,
  },
  ADMIN_CASE_COMMENTS: {
    URL: "/admin/arenden/:case_id/kommentarer",
    IS_ADMIN: true,
  },
  ADMIN_CASE_ACTIVITY_LOG: {
    URL: "/admin/arenden/:case_id/aktivitetslogg",
    IS_ADMIN: true,
  },
  ADMIN_CASE_CRIMES: {
    URL: "/admin/arenden/:case_id/kalldata/:data_source_id/brott",
    IS_ADMIN: true,
  },
  ADMIN_CASE_EMAIL_THREAD: {
    URL: "/admin/arenden/:case_id/kalldata/:data_source_id/epost",
    IS_ADMIN: true,
  },
  ADMIN_PERMISSION_LIST: {
    URL: "/admin/konfiguration/anvandarrattigheter/rattigheter",
    IS_ADMIN: true,
  },
  ADMIN_EMAIL_ACCOUNTS_LIST: {
    URL: "/admin/konfiguration/epost/epost-konton",
    IS_ADMIN: true,
  },
  ADMIN_COURTS_LIST: {
    URL: "/admin/konfiguration/arenden/domstolar",
    IS_ADMIN: true,
  },
  ADMIN_CRIMES_LIST: {
    URL: "/admin/konfiguration/arenden/brott",
    IS_ADMIN: true,
  },
  ADMIN_ACTIVITY_LOG_LIST: {
    URL: "/admin/konfiguration/arenden/aktivitetslogg",
    IS_ADMIN: true,
  },
  ADMIN_EMAIL_TEMPLATE_LIST: {
    URL: "/admin/konfiguration/epost/epost-mallar",
    IS_ADMIN: true,
  },
  ADMIN_ROLES_LIST: {
    URL: "/admin/konfiguration/anvandarrattigheter/roller",
    IS_ADMIN: true,
  },
  CONFIRM_ORDER: { URL: "/bekrafta-bestallning" },
  WIZARD_SEARCH: { URL: "/bestallning/sok" },
  WIZARD_SELECT_SERVICES: { URL: "/bestallning/sok?ssnnumber=" },
  WIZARD_CONFIRM_ORDER: { URL: "/bestallning/bekrafta-bestallning" },
  WIZARD_ORDER_COMPLETED: { URL: "/bestallning/bestallningsbekraftelse" },
  ADMIN_WIZARD_SELECT_CUSTOMER: {
    URL: "/admin/bestallning/valj-kund",
    IS_ADMIN: true,
  },
  ADMIN_RULES_LIST: {
    URL: "/admin/konfiguration/arenden/rapport-regler",
    IS_ADMIN: true,
  },
  ADMIN_BLOCKED_CANDIDATES_LIST: {
    URL: "/admin/konfiguration/arenden/blockerade-kandidater",
    IS_ADMIN: true,
  },

  ORDER_WITH_NO_SSN_DETAILS: {
    URL: "/bestallning/utan-personnummer/detaljer",
  },
  ORDER_WITH_NO_SSN_SELECT_SERVICES: {
    URL: "/bestallning/utan-personnummer/valj-tjanster",
  },
  ORDER_WITH_NO_SSN_CHECKOUT: {
    URL: "/bestallning/utan-personnummer/bekrafta-bestallning",
  },
  ORDER_WITH_NO_SSN_ORDER_CONFIRMATION: {
    URL: "/bestallning/utan-personnummer/bestallningsbekraftelse",
  },
  ORDER_COMPANY_DETAILS: {
    URL: "/bestallning/foretag/detaljer",
  },
  ORDER_COMPANY_SELECT_SERVICES: {
    URL: "/bestallning/foretag/valj-tjanster",
  },
  ORDER_COMPANY_CHECKOUT: {
    URL: "/bestallning/foretag/bekrafta-bestallning",
  },
  ORDER_COMPANY_ORDER_CONFIRMATION: {
    URL: "/bestallning/foretag/bestallningsbekraftelse",
  },
};
export default Routes;
