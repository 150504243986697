import React from "react";
import PropTypes from "prop-types";

import { CloseButton } from "@components/Button";
import Div from "@components/Div";
import { H2 } from "@components/Heading";
import Icon from "@components/Icon";
import Steps from "@components/Steps";

import { useStepperNavigation } from "@contexts/StepperContext";

const StepperHeader = ({ onClose }) => {
  const { activeStepItem, activeStepIndex, steps } = useStepperNavigation();

  return (
    <Div flex={1} display="flex" alignItems="center">
      <Div flex={2} display="flex" alignItems="center">
        <H2 color="var(--blue-dark) !important" mr={5}>
          {activeStepItem?.label}
        </H2>

        <Div flex={1} display={["none", "none", "block", "block"]}>
          <Steps model={steps} activeIndex={activeStepIndex} />
        </Div>
      </Div>

      <Div ml={5} display="flex" justifyContent="flex-end">
        <CloseButton
          rounded
          text
          icon={<Icon p={2} name="close" fontSize="var(--fs-icon-m)" />}
          borderRadius="5px !important"
          backgroundColor="var(--blue-lightest) !important"
          onClick={onClose}
        />
      </Div>
    </Div>
  );
};

StepperHeader.propTypes = {
  onClose: PropTypes.func,
};

export default StepperHeader;
