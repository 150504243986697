import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import Checkbox from "@components/Checkbox";
import Currency from "@components/Currency";
import Div from "@components/Div";
import { H5 } from "@components/Heading";
import { SmallEllipseIcon, BundleIcon } from "@components/Icon";
import { Text } from "@components/Text";
import Tooltip from "@components/ToolTip";

import useAuthorization from "@hooks/useAuthorization";

import { CustomerPermissions } from "@src/enum/Permissions";

import { BUNDLE } from "@utils/constant";
import { isCustomerUser } from "@utils/utils";

const AvailableServicesListItem = ({ service, onSelect }) => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();

  const hasPricePermission = hasAllPermissions([
    CustomerPermissions.CustomerReadPrices,
  ]);

  const getPriceString = service => {
    const shouldRenderPrepaidLabel =
      isCustomerUser() && service?.purchase_by_credits;

    if (!hasPricePermission) {
      return "";
    }

    if (shouldRenderPrepaidLabel) {
      return messages.prepaid_price_label;
    }

    const currencyString = <Currency value={service?.price} />;

    return currencyString;
  };

  return (
    <Div
      key={service.id}
      alignItems="center"
      display="flex"
      justifyContent="space-between"
    >
      <Div
        display="inline-flex"
        alignItems="center"
        wordBreak="keep-all"
        flex={1}
      >
        <Checkbox checked={service?.isChecked} onChange={onSelect} />

        <Div ml={2} display="flex" alignItems="center">
          <H5>
            {service.name}

            {service.service_type === BUNDLE && (
              <BundleIcon ml={2} name="bundle-tag"></BundleIcon>
            )}

            <Tooltip
              id={`${service.id}-${service.name}`}
              key={`${service.id}-${service.name}`}
              content={service.description}
              target={`.serviceName-${service.id}`}
              isHtml={true}
              event="both"
            />

            <SmallEllipseIcon
              ml={2}
              name="info"
              className={`serviceName-${service.id}`}
              data-pr-tooltipOptions={{ autoHide: false }}
            />
          </H5>
        </Div>
      </Div>

      <Div
        ml={1}
        display="inline-flex"
        justifyContent="end"
        width="80px"
        textAlign="right"
      >
        <Text
          fontWeight="var(--medium-weight) !important"
          fontSize={"var(--fs-text-m) !important"}
          lineHeight={"var(--fs-text-m) !important"}
          whiteSpace={"nowrap"}
        >
          {getPriceString(service)}
        </Text>
      </Div>
    </Div>
  );
};

AvailableServicesListItem.propTypes = {
  service: PropTypes.object,
  onSelect: PropTypes.func,
};

export default AvailableServicesListItem;
