import React from "react";
import { useIntl } from "react-intl";

import SpecialOrderLogo from "@assets/special_order.jpg";

import Card from "@components/Card";
import Div from "@components/Div";
import { H1 } from "@components/Heading";
import Logo from "@components/Logo";
import { TextMediumWeight } from "@components/Text";

import CandidateForm from "./components/CandidateForm/CandidateForm";
import { useCandidateInfo } from "./CandidateInfo.hooks";

const CandidateInfo = () => {
  const { handleSubmit } = useCandidateInfo();
  const { messages } = useIntl();

  return (
    <Div display="flex" flexDirection="column" w={1} gridGap={4}>
      <Div
        display="flex"
        flexDirection={["column-reverse", "column-reverse", "row"]}
        gridGap={3}
      >
        <Div width={1}>
          <Card p={3} borderRadius="10px">
            <Div display="flex" flexDirection="column" gridGap="1.5rem">
              <H1>{messages.heading_special_order}</H1>

              <TextMediumWeight color="var(--grey) !important">
                {messages.details_special_order}

                <br />

                {[
                  messages.details_special_order_option_1,
                  messages.details_special_order_option_2,
                  messages.details_special_order_option_3,
                ].map((message, index) => (
                  <Div ml={1} key={index}>
                    {`• ${message}`} <br />
                  </Div>
                ))}
              </TextMediumWeight>
            </Div>
          </Card>
        </Div>

        <Logo
          logo={SpecialOrderLogo}
          borderRadius="10px"
          width={[1, 1, "50%"]}
          maxWidth={["100%", "100%", "448px"]}
        />
      </Div>

      <CandidateForm onSubmit={handleSubmit} />
    </Div>
  );
};

export default CandidateInfo;
