import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import customerDetailsService from "@app/services/customers/customerDetailsService";
import { deleteCustomerProfile } from "@app/services/users/deleteUserProfileService";

import Breadcrumb from "@components/Breadcrumb";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Div from "@components/Div";
import Icon from "@components/Icon";
import Dialog from "@components/Dialog";
import { LinkArrow } from "@components/Link";
import ProgressSpinner from "@components/ProgressSpinner";
import Skeleton from "@components/Skeleton";
import Span from "@components/Span";
import { default as StyledTabs } from "@components/Tabs";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { AdminPermissions } from "@src/enum/Permissions";
import AdminContainer from "@layout/AdminContainer";

import List from "@pages/admin/watchlist/list";

import { loadActiveSubscriptionData } from "@utils/common";
import {
  ROUTES,
  ADMIN_CUSTOMER_DETAILS,
  ADMIN_CUSTOMER_USERS,
  ADMIN_CUSTOMER_ORDERS,
  ADMIN_CUSTOMER_CREDITS,
  ADMIN_BILLING_ACCOUNTS,
  ROLE_ANALYSIS,
  ADMIN_WATCHLIST_URL,
  INTEGRATIONS_API,
  RAPPORT,
} from "@utils/constant";
import { formatDateAndTime } from "@utils/utils";

import Analysis from "./tabs/Analysis";
import BillingAccountsTab from "./tabs/BillingAccountsTab";
import CompanyInfo from "./tabs/CompanyInfo";
import CommentsTab from "./tabs/CommentsTab";
import IntegrationsAPILogs from "./tabs/IntegrationsAPILogs";
import Orders from "./tabs/Orders";
import Products from "./tabs/Products";
import Users from "./tabs/Users";
import ActivityLogsTab from "./tabs/ActivityLogsTab";

import SelectedSubscriptionDetails from "../../shared/subscription/SelectedSubscriptionDetails";

const Details = () => {
  const { hasAllPermissions } = useAuthorization();
  const history = useHistory();
  const { messages } = useIntl();
  const { id = "", subscription_id = "" } = useParams();
  const { showErrorToast } = useToast();

  const [customerData, setCustomerData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [activeSubscription, setActiveSubscription] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(subscription_id);

  const { location: { state = {} } = {} } = history;
  const { analysis_type: analysisType = ROLE_ANALYSIS } = customerData;
  const { status } = state;

  const CustomerName =
    customerData?.name ||
    customerData?.firstname + " " + customerData?.lastname ||
    messages.title_customer;

  const breadCrumbItems = [
    {
      label: messages.title_customers,
      url: ROUTES.ADMIN_CUSTOMERS.URL,
    },
    {
      label: isLoading ? <Skeleton height={20} width={150} /> : CustomerName,
    },
  ];

  const hasUsersPermission = hasAllPermissions([
    AdminPermissions.AdminReadCustomerUsers,
  ]);

  const hasPermissionToActivityLogs = hasAllPermissions([
    AdminPermissions.AdminReadCustomersActivityLog,
  ]);

  const hasPermissionToOrders = hasAllPermissions([
    AdminPermissions.AdminReadOrders,
  ]);

  const hasPermissionToWatchlists = hasAllPermissions([
    AdminPermissions.AdminReadWatchLists,
  ]);

  const hasPermissionToProducts = hasAllPermissions([
    AdminPermissions.AdminReadProducts,
  ]);

  const hasPermissionToReports = hasAllPermissions([
    AdminPermissions.AdminReadReportAnalysis,
  ]);

  const hasPermissionToSubscriptions = hasAllPermissions([
    AdminPermissions.AdminReadSubscription,
  ]);

  const hasPermissionToBilling = hasAllPermissions([
    AdminPermissions.AdminReadBilling,
  ]);

  const updateCustomerData = useCallback(data => {
    setCustomerData(prevData => ({
      ...prevData,
      ...data,
    }));
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const tabContent = useMemo(
    () => [
      {
        title: messages.label_details,
        content: (
          <>
            {!!Object.keys(customerData).length && (
              <CompanyInfo
                customerData={customerData}
                onCustomerDelete={handleCloseDialog}
                id={id}
                status={status}
                updateCustomerData={updateCustomerData}
              />
            )}
          </>
        ),
        url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_CUSTOMER_DETAILS}`,
      },
      {
        title: messages.title_users,
        content: <Users customerId={id} />,
        url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_CUSTOMER_USERS}`,
        isHidden: !hasUsersPermission,
      },
      {
        title: messages.label_order_page_header,
        content: <Orders customerId={id} />,
        url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_CUSTOMER_ORDERS}`,
        isHidden: !hasPermissionToOrders,
      },
      {
        title: messages.title_prepaid_account,
        content: (
          <SelectedSubscriptionDetails
            customerDetails={customerData}
            subscriptionId={subscriptionId}
            customerId={id}
          />
        ),
        url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_CUSTOMER_CREDITS}/${subscriptionId}`,
        isHidden: !(activeSubscription && hasPermissionToSubscriptions),
      },
      {
        title: messages.menu_reports,
        content: <Analysis customerId={id} />,
        url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${RAPPORT}`,
        isHidden: !(analysisType === ROLE_ANALYSIS && hasPermissionToReports),
      },
      {
        title: messages.menu_watchlist,
        content: <List customerId={id} />,
        url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_WATCHLIST_URL}`,
        isHidden: !hasPermissionToWatchlists,
      },
      {
        title: messages.label_services,
        content: (
          <Products
            customerId={id}
            customProduct={customerData.custom_product}
          />
        ),
        url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/tjanster`,
        isHidden: !hasPermissionToProducts,
      },
      {
        title: messages.tab_billing,
        content: (
          <BillingAccountsTab
            customer_id={id}
            updateCustomerData={updateCustomerData}
            customerData={customerData}
            isInvoiceEnabled={Boolean(customerData?.invoice)}
            isInvoiceAttachmentsEnabled={Boolean(
              customerData?.invoice_attachments
            )}
            hasActiveSubscription={Boolean(activeSubscription)}
          />
        ),
        url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_BILLING_ACCOUNTS}`,
        isHidden: !hasPermissionToBilling,
      },
      {
        title: messages.header_api,
        content: (
          <IntegrationsAPILogs
            customerData={customerData}
            updateCustomerDetails={updateCustomerData}
          />
        ),
        url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${INTEGRATIONS_API}`,
      },
      {
        title: messages.label_comments,
        content: <CommentsTab id={id} />,
        url: `/admin/kunder/${id}/kommentarer`,
      },
      {
        title: messages.activity_log,
        content: <ActivityLogsTab id={id} />,
        url: `/admin/kunder/${id}/aktivitetslogg`,
        isHidden: !hasPermissionToActivityLogs,
      },
    ],
    [
      activeSubscription,
      analysisType,
      customerData,
      handleCloseDialog,
      hasPermissionToActivityLogs,
      hasPermissionToBilling,
      hasPermissionToOrders,
      hasPermissionToProducts,
      hasPermissionToReports,
      hasPermissionToSubscriptions,
      hasPermissionToWatchlists,
      hasUsersPermission,
      id,
      messages.activity_log,
      messages.header_api,
      messages.label_comments,
      messages.label_details,
      messages.label_order_page_header,
      messages.label_services,
      messages.menu_reports,
      messages.menu_watchlist,
      messages.tab_billing,
      messages.title_prepaid_account,
      messages.title_users,
      status,
      subscriptionId,
      updateCustomerData,
    ]
  );

  const _activeTabIndex = useMemo(
    () =>
      Math.max(
        tabContent.findIndex(item => item.url === history?.location?.pathname),
        0
      ),
    [history?.location?.pathname, tabContent]
  );

  const [activeIndex, setActiveIndex] = useState(_activeTabIndex);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setIsLoading(true);

        const [
          subscriptionResponse = {},
          { data: { data: customerDetailsResponse = {} } = {} } = {},
        ] = await Promise.all([
          hasPermissionToSubscriptions &&
            loadActiveSubscriptionData(
              {
                customer: {
                  id,
                },
              },
              "&include=auditLogs,customer"
            ),
          customerDetailsService(id),
        ]);
        const { id: subscription_id = "" } = subscriptionResponse;
        const formattedCustomerData = {
          ...customerDetailsResponse,
          createdAt: customerDetailsResponse.created_at
            ? formatDateAndTime(customerDetailsResponse.created_at)
            : "",
        };

        setSubscriptionId(subscription_id);
        setActiveSubscription(subscription_id ? subscriptionResponse : false);
        setCustomerData(formattedCustomerData);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDetails();
  }, [
    id,
    messages.exception_error_message,
    showErrorToast,
    hasPermissionToSubscriptions,
  ]);

  const handleDeleteDialog = async () => {
    try {
      const { id: customerId } = customerData;
      await deleteCustomerProfile({
        customerId,
      });
      history.push(ROUTES.ADMIN_CUSTOMERS.URL);
    } catch (e) {
      throw new Error(e);
    }
  };

  const handleTabChange = event => {
    const { index } = event;
    const targetUrl = tabContent[index]?.url;
    const targetTabIndex = Math.max(
      tabContent.findIndex(({ url }) => url === targetUrl),
      0
    );

    setActiveIndex(targetTabIndex);

    window.history.pushState(null, "", targetUrl);
  };

  const dialogHeader = () => (
    <Div textAlign="center" width={1}>
      <Span upper semibold fontSize="var(--fs-h4) !important">
        {messages.label_delete_customer}
      </Span>
    </Div>
  );

  const headerConfig = {
    title: CustomerName,
  };

  return (
    <div className="customer-details">
      <AdminContainer config={headerConfig} isLoading={isLoading} wide={true}>
        <Breadcrumb items={breadCrumbItems} p={0} mb={3} />

        <StyledTabs
          content={tabContent}
          onTabChange={handleTabChange}
          activeIndex={activeIndex}
        />

        {isLoading ? (
          <ProgressSpinner />
        ) : (
          <Div px={0} py={0}>
            {openDialog && (
              <Dialog
                header={dialogHeader}
                visible="displayBasic"
                draggable={false}
                onHide={handleCloseDialog}
                width={["90%", "90%", "90%", 445]}
              >
                <>
                  <Div
                    alignItems="center"
                    lineHeight="130%"
                    display="flex"
                    flexDirection="column"
                    mt={30}
                  >
                    <Span medium light>
                      {messages.customer_delete_message}
                    </Span>
                    <Span medium light>
                      {messages.customer_delete_description}
                    </Span>
                    <Div mt={20}>
                      <LinkArrow mr={2} info semibold>
                        {messages.label_more_about_gdpr}
                        <Icon
                          name="headerarrowright"
                          color={"var(--turquoise)"}
                        />
                      </LinkArrow>
                    </Div>
                  </Div>
                  <Div
                    mt={27}
                    display="flex"
                    justifyContent="center"
                    flexDirection={[
                      "column-reverse",
                      "column-reverse",
                      "column-reverse",
                      "row",
                    ]}
                  >
                    <PrimaryButtonOutlined
                      rounded
                      semibold
                      label={messages.label_cancel}
                      onClick={handleCloseDialog}
                      mr={[0, 0, 0, 30]}
                      width={[1, 1, 1, "45%"]}
                    />
                    <PrimaryButton
                      rounded
                      semibold
                      label={messages.label_delete}
                      onClick={handleDeleteDialog}
                      mb={[4, 4, 4, 0]}
                      width={[1, 1, 1, "45%"]}
                    />
                  </Div>
                </>
              </Dialog>
            )}
          </Div>
        )}
      </AdminContainer>
    </div>
  );
};

export default Details;
